import produce from 'immer';

export default function cart(state = {}, action) {
  switch (action.type) {
    case '@orders/ORDER_SUCCESS':
      return produce(state, (draft) => {
        const { order } = action;
        draft.order = order;
      });

    case '@orders/ORDER_ANALYZE':
      return produce(state, (draft) => {
        const { analyze } = action;
        console.log(action);
        console.log(analyze);
        draft.order = { ...action };
      });

    default:
      return state;
  }
}
