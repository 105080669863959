import React, { useLayoutEffect, useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { IoLogoWhatsapp } from 'react-icons/io';
import * as S from './styles';
import * as ModalActions from '../../store/modules/modal/actions';
import { formatPrice } from '../../util/format';
import api from '../../services/api';

const ModalOptin = ({ data, onToggleModalOptin, statusModalOptin }) => {
  const [loading, setLoading] = useState(false);
  const [cellphone, setCellphone] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const modal = useRef();

  function handleClickOutside(e) {
    if (modal.current && !modal.current.contains(e.target)) {
      onToggleModalOptin(!statusModalOptin);
    }
  }

  // Permite que o modal seja fechado ao clicar fora da tela
  useLayoutEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener('mousedown', handleClickOutside);

      return () => {
        window.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);

  const handleWpp = async () => {
    const urlMobile = `https://api.whatsapp.com/send?text=Oi%2C+eu+gostaria+de+receber+notifica%C3%A7%C3%B5es+da+Dominos+Cambuí.&phone=5511933517092`;
    const urlDesktop = `https://web.whatsapp.com/send?text=Oi%2C+eu+gostaria+de+receber+notifica%C3%A7%C3%B5es+da+Dominos+Cambuí.&phone=5511933517092`;
    if (!cellphone || cellphone === '' || cellphone.length !== 11)
      toast.error('Preencha corretamente o número de telefone!');
    else {
      await api.post('/cellphone', { phone_number: cellphone });
      window.open(isMobile ? urlMobile : urlDesktop);
      onToggleModalOptin(!statusModalOptin);
    }
  };

  return (
    <S.ContainerModal>
      <S.ContentModal>
        <S.CardModal ref={modal}>
          <S.CardModalHeader>Acompanhe seu pedido</S.CardModalHeader>
          <S.CardModalMain>
            <label>
              Seu pedido foi finalizado com sucesso! Caso queira acompanhar seu
              pedido por Whats App, preencha o campo abaixo. Esse é nosso canal
              de relacionamento com VOCÊ, cliente CM11PAPX. Salve nosso número
              na agenda do seu celular. Ainda estamos em fase de testes e em
              breve teremos muitas novidades.
            </label>
            <S.FormIcon>
              <IoLogoWhatsapp color="#4fce5d" size={32} />
              <input
                type="text"
                placeholder="Ex.: 11978585857"
                onChange={(e) => setCellphone(e.target.value)}
                value={cellphone}
              />
              <br />
              <br />
              <input
                type="checkbox"
                id="confirm"
                name="confirm"
                checked={isChecked}
                onClick={() => setIsChecked(!isChecked)}
              />
              <label htmlFor="confirm">
                Eu aceito receber mensagens da CM11PAPX
              </label>
            </S.FormIcon>
            <button type="button" onClick={handleWpp} disabled={!isChecked}>
              Confirmar
            </button>
          </S.CardModalMain>
        </S.CardModal>
      </S.ContentModal>
    </S.ContainerModal>
  );
};

const mapStateToProps = (state) => ({
  statusModalOptin: state.modal.statusOptin,
});

const mapDispatchToProps = (dispatch) => ({
  onToggleModalOptin: (statusModalOptin) =>
    dispatch(ModalActions.setModalToggleOptin(statusModalOptin)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalOptin);
