import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { IoIosRemove, IoIosAdd } from 'react-icons/io';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as S from './styles';
import api from '../../services/api';
import { formatPrice } from '../../util/format';

import * as ModalActions from '../../store/modules/modal/actions';
import * as CartActions from '../../store/modules/cart/actions';

const ModalAddCart = ({
  id,
  onToggleModalAddCart,
  statusModalAddCart,
  onAddProductToCart,
  cart,
}) => {
  const [product, setProduct] = useState(null);
  const [additionals, setAdditionals] = useState([]);
  const [amountProduct, setAmountProduct] = useState(1);
  const [meat, setMeat] = useState('');
  const [sauce, setSauce] = useState('');

  const modal = useRef();

  function handleClickOutside(e) {
    if (modal.current && !modal.current.contains(e.target)) {
      onToggleModalAddCart(!statusModalAddCart);
    }
  }

  // Permite que o modal seja fechado ao clicar fora da tela
  useLayoutEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener('mousedown', handleClickOutside);

      return () => {
        window.removeEventListener('mousedown', handleClickOutside);
      };
    }
    return false;
  }, []);

  useEffect(() => {
    async function fetchData() {
      const additionalsApi = await api.get(`additionals`);

      const additionalsAux = additionalsApi.data.map((options) => {
        const additionalOption = {
          ...options,
          checked: false,
        };
        return additionalOption;
      });

      const response = await api.get(`product/${id}`);

      const formattedPrice = formatPrice(response.data.price);

      const dataProduct = {
        ...response.data,
        formattedPrice,
        amount: amountProduct,
      };

      setProduct(dataProduct);
      setAdditionals(additionalsAux);
    }

    fetchData();
  }, [id]);

  // useEffect(() => {
  //   console.log(additionals);
  // }, [additionals]);

  const additionalItens = [
    {
      title: 'Carne',
      name: 'meat',
      options: [
        { id: 'mal', label: 'Mal passada' },
        { id: 'ponto', label: 'Ao Ponto' },
        { id: 'bem', label: 'Bem passada' },
      ],
    },
    {
      title: 'Molho',
      name: 'suace',
      options: [
        { id: 'molhoIngles', label: 'Molho Inglês' },
        { id: 'molhoDefumada', label: 'Mostarda Defumada' },
      ],
    },
  ];

  const handleUpdateAmount = (type) => {
    if (type === 'increment') {
      setAmountProduct(amountProduct + 1);
    } else if (type === 'decrement') {
      if (amountProduct < 1) return false;
      setAmountProduct(amountProduct - 1);
    } else {
      return false;
    }

    setProduct({ ...product, amount: amountProduct });
  };

  const handleAddToCart = () => {
    if (sauce.length === 0 || meat.length === 0) {
      toast.error('Um dos itens obrigatórios não foi marcado');
      return;
    }
    onAddProductToCart(product.id, amountProduct);

    let productsArr = [...cart];

    if (localStorage.hasOwnProperty('delivery-products')) {
      productsArr = JSON.parse(localStorage.getItem('delivery-products'));
    }

    productsArr.push(product);

    localStorage.setItem('delivery-products', JSON.stringify(productsArr));
  };

  // useEffect(() => {
  //   console.log('entrou-carrinho');
  //   localStorage.setItem('delivery-front: product/cart', []);
  // }, []);

  const handleAdditionalCheck = (id) => {
    const additionalAux = [...additionals];

    const additionalFiltered = additionalAux.find((item) => item.id === id);

    additionalFiltered.checked = !additionalFiltered.checked;

    setAdditionals(additionalAux);
  };

  const handleChangeOptional = (event, additional, option) => {
    const div = event.target;
    if (additional === 'meat') setMeat(option);
    if (additional === 'suace') setSauce(option);
    if (div.type === undefined) {
      if (div.tagName.toLowerCase() === 'span') {
        const parentDiv = div.parentElement;
        const fSet = parentDiv.querySelector('input');
        fSet.checked = true;
        return;
      }
      const fSet = div.querySelector('input');
      fSet.checked = true;
    }
  };

  return (
    <S.ContainerModal>
      <S.ContentModal>
        {product ? (
          <S.CardModal ref={modal}>
            <S.CardModalHeader>
              <img
                src={product.image}
                alt={`imagem do produto ${product.name}`}
              />
            </S.CardModalHeader>
            <S.CardTitleProduct>{product.description}</S.CardTitleProduct>

            <S.CardModalOptions>
              {additionalItens.map((additional) => (
                <div key={additional.name}>
                  <S.CardModalOptionsHeader>
                    <div>
                      <span>{additional.title}</span>
                      <span>Escolha 1 opção</span>
                    </div>
                    <span className="required">Obrigatório</span>
                  </S.CardModalOptionsHeader>
                  {additional.options.map((option) => {
                    return (
                      <S.CardModalOptionsRow
                        key={option.id}
                        onClick={(e) =>
                          handleChangeOptional(e, additional.name, option.id)
                        }
                      >
                        <span>{option.label}</span>
                        <input
                          type="radio"
                          name={additional.name}
                          value={option.id}
                        />
                      </S.CardModalOptionsRow>
                    );
                  })}
                </div>
              ))}

              <S.CardModalOptionsHeader>
                <div>
                  <span>Escolha os adicionais</span>
                  <span>Escolha quantos você preferir</span>
                </div>
                <span className="required">Opcional</span>
              </S.CardModalOptionsHeader>

              {additionals &&
                additionals.map((options) => (
                  <S.CardModalOptionsRow
                    key={options.id}
                    onClick={() => handleAdditionalCheck(options.id)}
                  >
                    <span>{options.description}</span>
                    <S.Checkbox check={options.checked} name={options.id} />
                  </S.CardModalOptionsRow>
                ))}

              <S.CardModalOptionsHeader>
                <div>
                  <span>Alguma observação?</span>
                </div>
                <span className="required">Opcional</span>
              </S.CardModalOptionsHeader>

              <S.GroupArea>
                <textarea placeholder="Digite aqui uma observação sobre seu pedido" />
              </S.GroupArea>

              <S.CardModalOptionsFooter>
                <S.WrapperGroupFooter>
                  <span className="QtdProductsTitle">
                    Quantidade de produtos
                  </span>
                  <S.BoxQtdProduct>
                    <S.ButtonQtdProduct
                      remove
                      onClick={() => handleUpdateAmount('decrement')}
                    >
                      <IoIosRemove size={24} />
                    </S.ButtonQtdProduct>

                    <S.QtdProduct>{amountProduct}</S.QtdProduct>

                    <S.ButtonQtdProduct
                      onClick={() => handleUpdateAmount('increment')}
                    >
                      <IoIosAdd size={24} />
                    </S.ButtonQtdProduct>
                  </S.BoxQtdProduct>
                </S.WrapperGroupFooter>

                <S.ButtonAddToCart onClick={() => handleAddToCart()}>
                  Adicionar
                </S.ButtonAddToCart>
              </S.CardModalOptionsFooter>
            </S.CardModalOptions>
          </S.CardModal>
        ) : (
          <S.Loading>
            <span />
          </S.Loading>
        )}
      </S.ContentModal>
    </S.ContainerModal>
  );
};

const mapStateToProps = (state) => ({
  statusModalAddCart: state.modal.statusAddCart,
  cart: state.cart.products.map((product) => ({
    ...product,
    subtotal: formatPrice(product.price * product.amount),
  })),
});

const mapDispatchToProps = (dispatch) => ({
  onToggleModalAddCart: (statusModalAddCart) =>
    dispatch(ModalActions.setModalToggleAddCart(statusModalAddCart)),
  onAddProductToCart: (id, amount) =>
    dispatch(CartActions.addToCartRequest(id, amount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddCart);
