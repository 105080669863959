import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import * as S from './styles';
import api from '../../../services/api';

const Statistics = () => {
  const [entregadores, setEntregadores] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const responseCouriers = await api.get(`couriers`);

      setEntregadores(responseCouriers.data);
    }

    fetchData();
  }, [entregadores]);

  const renderCards = (card) => {
    const handleChangeStatus = async () => {
      toast.info('Atualizando status entregador...');
      await api.put(`courier/${card.id}`, {
        available: !card.available,
      });
      toast.success('Entregador atualizado!');
    };

    return (
      <S.Cards available={card.available} onClick={() => handleChangeStatus()}>
        <S.SuperiorCard>
          <S.SuperiorCardTitle>Entregador</S.SuperiorCardTitle>
          {card.name}
        </S.SuperiorCard>
        <S.InferiorCard>
          <S.InferiorCardTitle>Quantidade Entregas</S.InferiorCardTitle>
          {card.length || '0'}
        </S.InferiorCard>
      </S.Cards>
    );
  };

  return (
    <S.Container>
      <S.ContainerGrid>
        {entregadores.length > 0 &&
          entregadores.map((entregador) => renderCards(entregador))}
      </S.ContainerGrid>
    </S.Container>
  );
};

export default Statistics;
