import { combineReducers } from 'redux';

import cart from './cart/reducer';
import client from './client/reducer';
import order from './order/reducer';
import modal from './modal/reducer';
import admin from './admin/reducer';
import store from './store/reducer';

export default combineReducers({
  cart,
  client,
  order,
  modal,
  admin,
  store,
});
