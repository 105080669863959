import React, { useState, useEffect, useCallback } from 'react';
import { differenceInMinutes } from 'date-fns';
import { connect } from 'react-redux';
import * as S from './styles';
import api from '../../../services/api';
import { maskPhone } from '../../../util/mask';
import ModalDeliveryRoute from '../../ModalDeliveryRoute';
import ModalDeliveryBoy from '../../ModalDeliveryBoy';

import {
  ColorOrderGreen,
  ColorOrderRed,
  ColorOrderYellow,
} from '../../../styles/variables';

import * as ModalActions from '../../../store/modules/modal/actions';

function Board({
  onToggleModalDeliveryRoute,
  statusModalDeliveryRoute,
  onToggleModalDeliveryBoy,
  statusModalDeliveryBoy,
  store,
}) {
  const [orders, setOrders] = useState([]);
  const [modalStatusOrder, setModalStatusOrder] = useState(null);

  const [orderData, setOrder] = useState();

  const getOrders = useCallback(async () => {
    const response = await api.get(`orders/${store.id}/status`);
    setOrders(response.data);
  }, []);

  useEffect(() => {
    getOrders();
  }, [orders]);

  const handleOpenModalOrder = (order) => {
    setOrder(order);

    if (order.status === 3) {
      return onToggleModalDeliveryBoy(!statusModalDeliveryBoy);
    }
    onToggleModalDeliveryRoute(!statusModalDeliveryRoute);
  };

  const defineColor = (order) => {
    const timeDifference = differenceInMinutes(
      new Date(),
      new Date(order.updatedAt)
    );

    if (order.status === 1) {
      if (timeDifference > 3) return `${ColorOrderRed}`;
      if (timeDifference <= 1) return `${ColorOrderGreen}`;
      if (timeDifference >= 2) return `${ColorOrderYellow}`;
    } else if (order.status === 3) {
      if (timeDifference > 8) return `${ColorOrderRed}`;
      if (timeDifference <= 5) return `${ColorOrderGreen}`;
      if (timeDifference >= 6) return `${ColorOrderYellow}`;
    } else if (order.status === 5) {
      return `#394C51`;
    }

    if (timeDifference >= 15) return `${ColorOrderRed}`;
    if (timeDifference <= 10) return `${ColorOrderGreen}`;
    if (timeDifference >= 10) return `${ColorOrderYellow}`;
  };

  return (
    <>
      {statusModalDeliveryRoute && <ModalDeliveryRoute data={orderData} />}
      {statusModalDeliveryBoy && <ModalDeliveryBoy data={orderData} />}

      <S.Container>
        <S.WrapperOrders>
          {orders.map((order, index) => (
            <S.WrapperOrdersGroup key={index}>
              <S.WrapperOrdersGroupTitle>
                {order.status.name}
              </S.WrapperOrdersGroupTitle>
              <S.OrdersBox>
                {order.orders.map((orderProduct) => (
                  <S.CardOrder
                    key={orderProduct.id}
                    onClick={() => handleOpenModalOrder(orderProduct)}
                    bgColor={defineColor(orderProduct)}
                  >
                    <S.CardOrderNumber
                      bgColor={defineColor(orderProduct)}
                    >{`${maskPhone(
                      orderProduct.Client.phone_number.substring(2)
                    )}`}</S.CardOrderNumber>
                  </S.CardOrder>
                ))}
              </S.OrdersBox>
            </S.WrapperOrdersGroup>
          ))}
        </S.WrapperOrders>
      </S.Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  statusModalDeliveryRoute: state.modal.statusDeliveryRoute,
  statusModalDeliveryBoy: state.modal.statusDeliveryBoy,
  store: state.store.store,
});

const mapDispatchToProps = (dispatch) => ({
  onToggleModalDeliveryRoute: (statusModalDeliveryRoute) =>
    dispatch(
      ModalActions.setModalToggleDeliveryRoute(statusModalDeliveryRoute)
    ),
  onToggleModalDeliveryBoy: (statusModalDeliveryBoy) =>
    dispatch(ModalActions.setModalToggleDeliveryBoy(statusModalDeliveryBoy)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Board);
