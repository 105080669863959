import { createGlobalStyle } from 'styled-components';

import background from '../assets/images/background.png';
import bg from '../assets/images/bg.jpeg';
import bgNovo from '../assets/images/bg.png';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  /* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */
  /* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap'); */

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    /* background: #fafafa url(${background}) no-repeat center top; */
    /* background: #fafafa url(${bgNovo}) no-repeat center top; */
    background-image: url(${bg});
     /* background: #fff; */
  }

  body, input, button {
    font: 14px Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    /* font-family: 'Source Sans Pro', sans-serif; */
  }

  #root {
    max-width: 1220px;
    margin: 0 auto;
    padding: 0px 15px;
  }

  button {
    cursor: pointer;
  }
`;
