import styled from 'styled-components';
import { darken } from 'polished';
import { ColorText, ColorOrderRed } from '../../styles/variables';

export const CardReceipt = styled.div`
  background: #fff;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ddd;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const CardReceiptHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */

  .resume {
    display: flex;
    flex-direction: column;
    span {
      margin: 10px;
      font-size: 18px;
      font-weight: bold;
    }
  }

  border-bottom: 1px solid #ddd;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CardReceiptNumberOrder = styled.div`
  padding: 5px 20px;
  background: ${ColorText};
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  border-radius: 4px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 20px;
  }
`;

export const LinkWhatsapp = styled.button`
  text-decoration: none;
  display: inline-block;
  background: #26d366;
  border-radius: 4px;
  padding: 10px 40px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: ${darken(0.06, '#26d366')};
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
  }
`;

export const CardReceiptMain = styled.div`
  background: #f9f9f9;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  .receipt-not-product {
    padding: 80px;
    text-align: center;
    font-size: 22px;
    color: ${ColorOrderRed};
  }
`;

export const CardReceiptMainHeader = styled.div`
  display: grid;
  grid-template-columns: 40% 30% 30%;
  padding: 20px 0px;

  span {
    text-align: center;
    font-size: 16px;
    color: ${ColorText};
    font-weight: 500;
  }
`;

export const CardReceiptMainProduct = styled.div`
  display: grid;
  grid-template-columns: 40% 30% 30%;
  text-align: center;
  margin-bottom: 20px;

  div {
    display: grid;
    grid-template-columns: 44% 56%;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @media (max-width: 768px) {
        padding: 0px 30px;
      }
    }
    img {
      width: 60px;
      height: 60px;
      min-height: 60px;
      max-width: 60px;
      border-radius: 50%;
      object-fit: cover;
      margin-left: 60px;
      @media (max-width: 768px) {
        margin-left: 0px;
        margin-bottom: 15px;
      }
    }
  }

  span {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: ${ColorText};
    font-size: 14px;
    font-weight: 500;
  }
`;

export const CardReceiptInfo = styled.div`
  background: #f1eded;
  padding: 20px 0px;
  border-top: 1px solid #ddd;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const CardReceiptConfirmation = styled.div`
  background: #f1eded;
  padding: 20px 0px;
  border-top: 1px solid #ddd;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  justify-content: flex-end;
`;

export const CardReceiptInfoHeader = styled.div`
  display: grid;
  grid-template-columns: 40% 30% 30%;
  text-align: center;

  span {
    color: ${ColorText};
    font-weight: 700;
    font-size: 16px;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CardReceiptInfoContent = styled.div`
  display: grid;
  grid-template-columns: 40% 30% 30%;
  text-align: center;
  padding-top: 20px;

  span {
    color: #50a773;
    font-weight: 700;
    font-size: 16px;
  }
`;

export const ButtonCartEvent = styled.span`
  background: ${(props) => (props.back ? '#FF7F50' : '#26d366')};
  color: #fff;
  border: 0;
  border-radius: 4px;
  padding: 10px 30px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background 180ms ease-in-out;
  margin-right: 10px;
  align-self: flex-start;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-left: 0px;
  }

  &:hover {
    background: ${(props) =>
      props.back ? `${darken(0.04, '#FF7F50')}` : `${darken(0.04, '#26d366')}`};
  }
`;
