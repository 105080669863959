import React, { useRef, useState } from 'react';

import { toast } from 'react-toastify';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as cep from 'cep-promise';

import * as Yup from 'yup';

import Input from '../../components/Form/Input';

import {
  Container,
  Content,
  FormStyled,
  Columns,
  SelectStyled,
} from './styles';

import { formatPrice } from '../../util/format';
import * as CartActions from '../../store/modules/cart/actions';
import * as OrderActions from '../../store/modules/order/actions';
import history from '../../services/history';

function Register({
  cart,
  total,
  store,
  makeClientRequest,
  analyzeOrder,
  updateCartAux,
}) {
  const formRef = useRef(null);

  const [addressState, setAddress] = useState();
  const [districtState, setDistrict] = useState();
  const [payment, setPayment] = useState('Dinheiro');
  const [deliveryTo, setDeliveryTo] = useState('Entrega');
  const [changeTo, setChangeTo] = useState('');
  const getPaymentType = () => {
    if (payment === 'Dinheiro' && !changeTo)
      toast.error('Você precisa informar o troco');
  };

  async function handleSubmit(data, { reset }, event) {
    event.preventDefault();
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório.'),
        address: Yup.string().required('O endereço é obrigatório'),
        house_number: Yup.string().required('O número da casa é obrigatório'),
        district: Yup.string().required('O bairro é obrigatório'),
        complement: Yup.string().required('O complemento é obrigatório'),
        phone_number: Yup.string()
          .min(10, 'O número precisa ter no mínimo 10 caracteres')
          .matches(
            /^(([(]?)[0-9]{2}?([)]?))[0-9]{4,5}[0-9]{4}$/,
            'O telefone digitado é inválido!'
          )
          .required('O telefone é um campo obrigatório'),
        change_to: Yup.string().when('delivery', {
          is: (value) => value && value === 'Entrega',
          then: Yup.string()
            .required('É necessário definir o troco')
            .matches(
              /^(?!0\.00)\d{1,3}(,\d{3})*(\.\d\d)?$/,
              'Formato inválido'
            ),
        }),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (deliveryTo === 'Entrega' && payment === 'Dinheiro') {
        const regexChangeTo = /^(\d+(?:[\.\,]\d{1,2})?)$/;

        if (data.change_to.length <= 0 || !regexChangeTo.test(data.change_to)) {
          toast.error('Valor do troco é inválido');
          return;
        }
        if (data.change_to < total) {
          toast.error('Troco menor que valor total');
          return;
        }
        setChangeTo(data.change_to);
      }
      data.phone_number = `55${data.phone_number}`;
      // makeClientRequest(data);

      analyzeOrder(cart, data, store, deliveryTo, payment, data.change_to);
      updateCartAux();
      history.push('/receipt');
    } catch (err) {
      const validationErrors = {};
      console.log(err);

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      toast.error('Verifique os campos');
    }
  }

  async function handleCep(event) {
    // name="name" type="text" placeholder="Seu Nome"

    if (event.key === 'Enter' || event.type === 'blur') {
      try {
        const resp = await cep(event.target.value);
        if (resp.street) setAddress(resp.street);
        if (resp.neighborhood) setDistrict(resp.neighborhood);
      } catch (e) {
        toast.error('O CEP informado é inválido');
      }
    }
  }

  return (
    <Container>
      <Content>
        <h3>Informe alguns dados, para finalizar o seu pedido</h3>

        <FormStyled ref={formRef} onSubmit={handleCep}>
          <Input
            name="cep"
            type="text"
            placeholder="Informe o seu CEP"
            onKeyPress={(e) => handleCep(e)}
            onBlur={(e) => handleCep(e)}
          />
        </FormStyled>

        <FormStyled ref={formRef} onSubmit={handleSubmit}>
          <Input name="name" type="text" placeholder="Seu Nome" />
          <br />
          {!addressState ? (
            <Input name="address" type="text" placeholder="Sua Rua" />
          ) : (
            <Input
              name="address"
              type="text"
              placeholder="Sua Rua"
              value={addressState}
              style={{ width: '100%' }}
            />
          )}
          <br />
          <Input
            name="house_number"
            type="text"
            placeholder="Número da sua casa (ex: 49)"
          />
          <br />
          {!districtState ? (
            <Input name="district" type="text" placeholder="Seu bairro" />
          ) : (
            <Input
              name="district"
              type="text"
              placeholder="Seu bairro"
              value={districtState}
            />
          )}
          <br />
          <Input
            name="complement"
            type="text"
            placeholder="Complemento do endereço"
          />
          <br />
          <Input
            name="phone_number"
            type="text"
            placeholder="Seu telefone. ex: 99 99999-9999"
          />
          <br />
          <br />
          <label htmlFor="delivery">Escolha o tipo de entrega:</label>
          <SelectStyled
            name="delivery"
            id="delivery"
            value={deliveryTo}
            onChange={(e) => {
              setDeliveryTo(e.target.value);
            }}
          >
            <option value="Entrega">Entrega</option>
            <option value="Retirada no Local">Retirada no Local</option>
          </SelectStyled>

          <br />
          {deliveryTo === 'Entrega' && (
            <>
              <label htmlFor="payment">Escolha o tipo de pagamento:</label>
              <SelectStyled
                name="payment"
                id="payment"
                value={payment}
                onChange={(e) => {
                  if (e.target.value === 'Cartão de Crédito/Débito')
                    setChangeTo('');
                  setPayment(e.target.value);
                }}
              >
                <option value="Dinheiro">Dinheiro</option>
                <option value="Cartão de Crédito/Débito">
                  Cartão de Crédito/Débito
                </option>
              </SelectStyled>
            </>
          )}
          {payment === 'Dinheiro' && deliveryTo === 'Entrega' && (
            <>
              <Input
                id="change_to"
                name="change_to"
                type="text"
                placeholder="Troco para?"
              />
            </>
          )}
          <button type="submit">REALIZAR PEDIDO</button>
        </FormStyled>
      </Content>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  cart: state.cart.products.map((product) => ({
    ...product,
    subtotal: formatPrice(product.price * product.amount),
  })),
  total: state.cart.products.reduce((total, product) => {
    return total + product.price * product.amount;
  }, 0),
  client: state.client,
  store: state.store.store,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...OrderActions, ...CartActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Register);
