import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import './config/ReactotronConfig';

import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import GlobalStyle from './styles/global';
import Header from './components/Header';
import Routes from './routes';

import { store, persistor } from './store';
import history from './services/history';
import Footer from './components/Footer';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <DndProvider backend={HTML5Backend}>
          <Router history={history}>
            <Header />
            <Routes />
            <GlobalStyle />
            <ToastContainer autoClose={3000} />
            <Footer />
          </Router>
        </DndProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
