import produce from 'immer';

export default function client(state = {}, action) {
  switch (action.type) {
    case '@client/CREATE_SUCCESS':
      return produce(state, (draft) => {
        const { client } = action;
        draft.client = client;
      });

    default:
      return state;
  }
}
