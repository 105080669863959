export function setModalToggleAddCart(statusAddCart) {
  return {
    type: '@modal/TOGGLE_MODAL_ADD_CART',
    statusAddCart,
  };
}

export function setModalToggleDeliveryRoute(statusDeliveryRoute) {
  return {
    type: '@modal/TOGGLE_MODAL_DELIVERY_ROUTE',
    statusDeliveryRoute,
  };
}

export function setModalToggleDeliveryBoy(statusDeliveryBoy) {
  return {
    type: '@modal/TOGGLE_MODAL_DELIVERY_BOY',
    statusDeliveryBoy,
  };
}

export function setModalToggleOptin(statusOptin) {
  return {
    type: '@modal/TOGGLE_MODAL_OPTIN',
    statusOptin,
  };
}
