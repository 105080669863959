export function analyzeOrder(
  cart,
  client,
  store,
  deliverType,
  paymentType,
  changeTo
) {
  return {
    type: '@orders/ORDER_ANALYZE',
    cart,
    client,
    store,
    deliverType,
    paymentType,
    changeTo,
  };
}

export function makeOrderRequest(
  cart,
  client,
  store,
  deliverType,
  paymentType,
  changeTo,
  newValue
) {
  return {
    type: '@orders/ORDER_PREPARE',
    cart,
    client,
    store,
    deliverType,
    paymentType,
    changeTo,
    newValue,
  };
}

export function makeOrderSuccess(order) {
  return {
    type: '@orders/ORDER_SUCCESS',
    order,
  };
}

export function updateStatus(id, status) {
  return {
    type: '@orders/CHANGE_STATUS',
    id,
    status,
  };
}
