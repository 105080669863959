import { all } from 'redux-saga/effects';

import cart from './cart/sagas';
import client from './client/sagas';
import order from './order/sagas';
import store from './store/sagas';

export default function* rootSaga() {
  return yield all([cart, client, order, store]);
}
