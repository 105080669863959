import styled from 'styled-components';

export const Container = styled.div``;

export const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    div + div {
      margin-top: 15px;
    }
  }
`;

export const Cards = styled.div`
  display: flex;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  justify-content: space-around;
  flex-direction: column;
  background: #fff;
  padding: 20px 16px;
  border: 6px;
  border-color: #7159c1;
  /* border-color: rgba(25, 25, 25, 0.06); */
  box-shadow: 0 2px 4px 0
    ${(props) =>
      props.available ? 'rgba(32, 145, 70, 1)' : 'rgba(145, 32, 60, 1)'};

  border-radius: 8px;
`;

export const SuperiorCard = styled.div`
  width: 100%;
  padding: 0;
  font-size: 50px;
  line-height: 50px;
  font-weight: bold;
  letter-spacing: -1.29px;
  padding-bottom: 16px;
`;

export const SuperiorCardTitle = styled.div`
  font-size: 22px;
  line-height: 22px;
  letter-spacing: -0.65px;
  color: rgba(25, 25, 25, 0.66);
`;

export const InferiorCard = styled.div`
  width: 100%;
  padding: 0;
  font-size: 32px;
  line-height: 36px;
  font-weight: bold;
  letter-spacing: -0.83px;
`;

export const InferiorCardTitle = styled.div`
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.41px;
  color: rgba(25, 25, 25, 0.66);
`;
