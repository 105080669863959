import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { number } from 'yup';
import api from '../../../services/api';

import history from '../../../services/history';

import { makeOrderSuccess, analyzeOrder } from './actions';
import { clearCart } from '../cart/actions';
import { setModalToggleOptin } from '../modal/actions';

function* prepareOrder(
  cart,
  client,
  store,
  deliverType,
  paymentType,
  changeTo
) {
  // yield put(clearCart());
  yield put(
    analyzeOrder({
      analyze: { cart, client, store, deliverType, paymentType, changeTo },
    })
  );
}

function* makeOrder({
  cart,
  client,
  store,
  deliverType,
  paymentType,
  changeTo,
  newValue,
}) {
  const products = cart.map((c) => ({
    ...c,
    product_id: c.id,
    ammount: c.amount,
    additionals: [],
  }));

  try {
    const change_to = String(changeTo).includes(',')
      ? changeTo.replace(',', '.')
      : changeTo;

    const response = yield call(api.post, 'order', {
      products,
      client_id: client.id,
      delivery_type: deliverType,
      store_id: store.id,
      payment_type: paymentType,
      change_to: Number(change_to) || null,
      newValue,
    });

    toast.success(
      `Compra realizada, caso desejar acompanhar seu pedido via WhatsApp, clique no botão abaixo!`
    );
    yield put(makeOrderSuccess(response.data));
    yield put(clearCart());
    yield put(setModalToggleOptin(true));
    // history.push('/receipt');
  } catch (e) {
    console.log(e);
    toast.error(`Todo mundo erra, infelizmente desta vez fomos nós :'[`);
  }
}

export default all(
  [takeLatest('@orders/ORDER_PREPARE', makeOrder)]
  // [takeLatest('@orders/ORDER_ANALYZE', prepareOrder)]
);
