import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { darken, lighten } from 'polished';
import * as variables from '../../styles/variables';

export const Container = styled.div`
  /* padding: 30px; */
  background: #fafafa;
  border-radius: 4px;
  border: 1px solid;
  border-color: rgba(25, 25, 25, 0.2);
  box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f1eded;
    padding: 30px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    @media (max-width: 768px) {
      padding: 30px 15px;
      flex-direction: column;
      span {
        display: block;
      }

      .group-button {
        width: 100%;
        display: grid;
        grid-template-columns: 49% 49%;
        grid-column-gap: 2%;
        margin-bottom: 20px;
      }
    }

    a {
      text-decoration: none;
      @media (max-width: 768px) {
        span {
          text-align: center;
          padding: 12px 0px;
          font-size: 12px;
        }
      }
    }
  }
`;

export const ButtonCartEvent = styled.span`
  background: ${(props) => (props.back ? '#FF7F50' : '#26d366')};
  color: #fff;
  border: 0;
  border-radius: 4px;
  padding: 10px 30px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background 180ms ease-in-out;
  margin-left: 10px;

  @media (max-width: 768px) {
    margin-left: 0px;
  }

  &:hover {
    background: ${(props) =>
      props.back ? `${darken(0.04, '#FF7F50')}` : `${darken(0.04, '#26d366')}`};
  }
`;

export const EmptyCart = styled.section`
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 560px) {
    min-height: 400px;
  }
  svg {
    font-size: 260px;
    color: #f3f3f6;
    position: absolute;
  }
  div {
    z-index: 1;
    text-align: center;
    h2 {
      font-size: 56px;
    }
    p {
      margin: 12px 0 28px;
      font-size: 16px;
    }
  }
`;

export const StartShopping = styled(Link)`
    background: #26d366;
    color: #fff;
    border: 0;
    border-radius: 4px;
    padding: 12px 20px;
    font-weight: bold;
    text-transform: uppercase;
    transition: background 180ms ease-in-out;
    text-decoration: none;
    display: inline-block;
    &:hover {
      background: ${lighten(0.04, '#26d366')};
    }
    &:active {
      background: ${darken(0.04, '#26d366')};
    }
  }
`;

export const Total = styled.div`
  display: flex;
  align-items: baseline;

  span {
    color: #333;
    font-weight: 700;
    font-size: 14px;
  }

  strong {
    font-size: 22px;
    margin-left: 8px;
    color: #50a773;
  }
`;

export const Cart = styled.div`
  background: #f9f9f9;
  padding: 30px;
  border-bottom: 1px solid #ddd;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  .delete {
    background: #ececec;
    width: 40px;
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 50%;
    margin: auto;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    &:hover {
      background: ${darken(0.04, '#ececec')};
    }
  }
`;

export const CartProductHeader = styled.div`
  display: grid;
  grid-template-columns: 30% 30% 30% 10%;
  text-align: center;
`;

export const CartProduct = styled.div`
  display: grid;
  grid-template-columns: 30% 30% 30% 10%;
  text-align: center;
  margin: 20px 0px;

  @media (max-width: 768px) {
    margin: 40px 0px;
  }

  div {
    display: grid;
    grid-template-columns: 44% 56%;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      /* @media (max-width: 768px) {
        padding: 0px 30px;
      } */
    }
    img {
      width: 60px;
      height: 60px;
      min-height: 60px;
      max-width: 60px;
      border-radius: 50%;
      object-fit: cover;
      margin-left: 60px;
      @media (max-width: 768px) {
        margin-left: 0px;
        margin-bottom: 15px;
      }
    }
  }

  span {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: ${variables.ColorText};
    font-size: 14px;
    font-weight: 500;
  }
`;

export const BoxQtdProduct = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #3f3e3e;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonQtdProduct = styled.span`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center !important;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  display: block;
  border-radius: 50%;
  background: #ececec;
  font-size: 14px;

  &:hover {
    background: ${darken(0.06, '#ececec')};
  }
`;

export const QtdProduct = styled.span`
  display: flex;
  align-items: center;
  justify-content: center !important;
  font-size: 16px;
  min-width: 100px;
  width: 100px;
  text-align: center;
  padding: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Cupom = styled.div`
  color: #333;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }

  svg {
    align-self: flex-start;
    margin-top: 5px;
  }
`;

export const InputCupomGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const MsgCoupon = styled.div`
  display: flex;
  align-items: center;
  line-height: 22px;
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => (props.status ? '#89bd55' : '#ff4040')};
  margin-top: 5px;

  svg {
    margin: auto 0;
  }

  span {
    margin-left: 5px;
    font-weight: 700;
  }
`;

export const InputCupom = styled.input`
  width: 250px;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #ddd;
`;
