import styled from 'styled-components';
import { darken } from 'polished';
import { Link } from 'react-router-dom';

export const ItemDescription = styled.div`
  padding: 20px;

  span {
    display: block;
    line-height: 22px;
    /* & + span{
      margin-top: 20px;
    } */
  }
`;

export const ItemName = styled.span`
  color: #3f3e3e;
  font-size: 16px;
  font-weight: 500;
`;

export const ItemDetails = styled.p`
  color: #717171;
  font-size: 14px;
  line-height: 22px;
  margin-top: 10px;
  min-height: 80px;
`;

export const ItemPrice = styled.span`
  color: #50a773;
  font-weight: 500;
  font-size: 16px;
  margin-top: auto;
`;

export const ItemImage = styled.div`
  /* padding: 15px 15px 0px 15px; */

  height: 200px;

  img {
    /* max-width: 120px;
    border-radius: 50%; */
    /*
    @media (max-width: 768px) { */
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 4px 4px 0px 0px;
    max-width: none;
    min-height: 200px;
    height: 200px;
    /* } */
  }
`;

// export const Item = styled.div`
//   text-decoration: none;
//   display: block;
// `;

export const Container = styled.div`
  margin-bottom: 60px;
  h3 {
    color: #3f3e3e;
    font-weight: 500;
    font-size: 32px;
    margin: 40px 0px 20px 0px;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }

    &::after {
      content: '';
      width: 12%;
      height: 2px;
      margin: 1rem 0px 2rem 0px;
      border-radius: 4px;
      display: block;
      background: #757575;
      @media (max-width: 768px) {
        width: 40%;
      }
    }
  }
`;

export const CardItem = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: box-shadow 0.3s ease-in-out;
  background: #fafafa;

  &:hover {
    box-shadow: 0px 4px 5px 0px #ddd;
    cursor: pointer;
  }
`;

export const WrapperCard = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    div + div {
      margin-top: 15px;
    }
  }
`;
