import styled from 'styled-components';
import { ColorText } from '../../../styles/variables';

export const Container = styled.div`
  display: flex;
  padding: 30px 15px;
  height: calc(100% - 80px);
  /* background: #fafafa; */
  border-radius: 4px;
  /* border: 1px solid; */
  /* border-color: rgba(25, 25, 25, 0.08); */
  /* box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8); */
`;

export const WrapperOrders = styled.div`
  width: 100%;

  /* alterar para display flex no mobile */
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 15px;

  @media (max-width: 1050px) {
    display: flex;
    flex-direction: column;
  }
`;

export const WrapperOrdersGroup = styled.div`
  /* width: 100%; */
`;

export const WrapperOrdersGroupTitle = styled.span`
  font-size: 22px;
  color: ${ColorText};
  font-weight: 500;
  display: block;
  margin: 20px 0px;
  border-radius: 4px;
  text-align: center;
`;

export const OrdersBox = styled.div`
  @media (max-width: 1050px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 15px;
  }
`;

export const CardOrder = styled.div`
  padding: 10px 20px;
  border-radius: 30px;
  background: ${(props) => props.bgColor};
  box-shadow: 0px 4px 5px 0px #ddd;
  display: block;
  margin: 10px 0px;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    svg {
      margin-right: 7px;
    }
  }

  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const CardOrderNumber = styled.span`
  color: ${(props) => (props.bgColor === '#F0E68C' ? `${ColorText}` : '#fff')};

  font-weight: 700;
  font-size: 16px;
  padding: 0px 15px;
  border-radius: 25px;
  white-space: nowrap;
  text-align: center;
  margin: 0 auto;

  @media (max-width: 1050px) {
    padding: 0px;
  }
`;
