import React from 'react';
import { connect } from 'react-redux';
import * as S from './styles';
import * as AdminActions from '../../../store/modules/admin/actions';

const Menu = ({ onSetMenuStatus, statusMenu }) => {
  const menuOptions = [
    {
      id: 'statusPedido',
      label: 'Status pedidos',
    },
    {
      id: 'estatistica',
      label: 'Entregadores',
    },
    // {
    //   id: 'categoria',
    //   label: 'Categorias',
    // },
    // {
    //   id: 'produto',
    //   label: 'Produtos',
    // },
    // {
    //   id: 'adicionais',
    //   label: 'Adicionais',
    // },
  ];

  return (
    <S.Menu>
      {menuOptions.map((option) => (
        <S.MenuItem
          active={statusMenu.status === option.id}
          onClick={() => onSetMenuStatus(option.id)}
        >
          {option.label}
        </S.MenuItem>
      ))}
    </S.Menu>
  );
};

const mapStateToProps = (state) => ({
  statusMenu: state.admin.menu,
});

const mapDispatchToProps = (dispatch) => ({
  onSetMenuStatus: (menuStatus) =>
    dispatch(AdminActions.setMenuStatus(menuStatus)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
