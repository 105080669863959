import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';
import { Container, WhatsDiv } from './styles';

import * as OrderActions from '../../store/modules/order/actions';
import { formatPrice } from '../../util/format';
import history from '../../services/history';

function DeliveryChoice({ cart, client, store, makeOrderRequest }) {
  const getDeliveryType = (type) => {
    if (type === 2) makeOrderRequest(cart, client, store, type, null, null);
    else history.push('/payment-choice');
  };
  const urlWpp =
    'https://api.whatsapp.com/send?text=Gostaria%20de%20receber%20as%20notifica%C3%A7%C3%B5es%20da%20pizzaria%20-%20CM11&phone=5511933517092';
  return (
    <Container>
      <h1>Como deseja receber seu produto?</h1>

      <div>
        <button type="button" onClick={() => getDeliveryType(1)}>
          Receber o produto em casa
        </button>
        <button type="button" onClick={() => getDeliveryType(2)}>
          Recolher o produto na loja
        </button>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  cart: state.cart.products.map((product) => ({
    ...product,
    subtotal: formatPrice(product.price * product.amount),
  })),
  total: formatPrice(
    state.cart.products.reduce((total, product) => {
      return total + product.price * product.amount;
    }, 0)
  ),
  client: state.client,
  store: state.store.store,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(OrderActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryChoice);
