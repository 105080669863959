import React, { useLayoutEffect, useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import * as S from './styles';
import * as ModalActions from '../../store/modules/modal/actions';
import { formatPrice } from '../../util/format';
import api from '../../services/api';

const ModalDeliveryRoute = ({
  data,
  onToggleModalDeliveryRoute,
  statusModalDeliveryRoute,
}) => {
  const [loading, setLoading] = useState(false);
  const dateStamp = `${+new Date(data.createdAt)}`.substring(6, 13);

  const modal = useRef();

  // Permite que o modal seja fechado ao clicar fora da tela
  useLayoutEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener('mousedown', handleClickOutside);

      return () => {
        window.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);

  function handleClickOutside(e) {
    if (modal.current && !modal.current.contains(e.target)) {
      onToggleModalDeliveryRoute(!statusModalDeliveryRoute);
    }
  }

  const handleUpdateOrder = async (id, status) => {
    const update = await api.put('order', { id, status: status + 1 });

    setLoading(true);

    if (update.status === 200) {
      setLoading(false);
      onToggleModalDeliveryRoute(!statusModalDeliveryRoute);
      return toast.success(`Pedido atualizado com sucesso!`);
    }
    return toast.error(`Houve um erro ao atualizar o pedido`);
  };

  const renderNextPhase = (status) => {
    if (status === 5) {
      return '';
    }
    return (
      <S.ButtonNextStatus
        onClick={() => handleUpdateOrder(data.id, data.status)}
      >
        {!loading ? (
          <span>Avançar</span>
        ) : (
          <S.Loading>
            <span />{' '}
          </S.Loading>
        )}
      </S.ButtonNextStatus>
    );
  };

  return (
    <S.ContainerModal>
      <S.ContentModal>
        <S.CardModal ref={modal}>
          <S.CardModalHeader>
            <S.NumberOrder>{dateStamp}</S.NumberOrder>
            <S.DateOrder>
              {format(new Date(data.createdAt), 'dd/MM/yyyy')}
            </S.DateOrder>
            <S.HourOrder>
              {format(new Date(data.createdAt), 'HH:mm')}
            </S.HourOrder>
          </S.CardModalHeader>
          <S.CardModalMain>
            <S.HeaderProducts>
              <span>Produto</span>
              <span>Preço</span>
              <span>Quantidade</span>
              <span>Subtotal</span>
            </S.HeaderProducts>

            {data.items.map((product) => (
              <S.CardModalMainProduct key={product.product_id}>
                <div>
                  <img
                    src={product.Product.image}
                    alt={`${product.Product.name}`}
                  />
                  <span>{product.Product.name}</span>
                </div>

                <span>{formatPrice(product.Product.price)}</span>

                <span>{product.ammount}</span>

                <span>{formatPrice(product.price * product.ammount)}</span>
              </S.CardModalMainProduct>
            ))}

            <S.CardModalGroup>
              <p>
                <b>Observação:</b> {data.observation}
              </p>
              <S.CardModalTotal>{formatPrice(data.price)}</S.CardModalTotal>
            </S.CardModalGroup>

            <S.CardModalMainFooter>
              {data.delivery_type === 1 ? (
                <>
                  <span>
                    <b>Cliente:</b> {data.Client.name}
                  </span>
                  <span>
                    <b>Endereço:</b> {data.Client.address}
                  </span>
                  <span>
                    <b>Bairro:</b> {data.Client.district}
                  </span>
                  <span>
                    <b>Complemento:</b> {data.Client.complement}
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <b>Cliente:</b> {data.Client.name}
                  </span>
                  <span>
                    <b>Entrega:</b> Cliente vai receber na loja
                  </span>
                </>
              )}
              {renderNextPhase(data.status)}
            </S.CardModalMainFooter>
          </S.CardModalMain>
        </S.CardModal>
      </S.ContentModal>
    </S.ContainerModal>
  );
};

const mapStateToProps = (state) => ({
  statusModalDeliveryRoute: state.modal.statusDeliveryRoute,
});

const mapDispatchToProps = (dispatch) => ({
  onToggleModalDeliveryRoute: (statusModalDeliveryRoute) =>
    dispatch(
      ModalActions.setModalToggleDeliveryRoute(statusModalDeliveryRoute)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeliveryRoute);
