import React, { useEffect, useState } from 'react';
import { MdDelete, MdRemoveShoppingCart, MdErrorOutline } from 'react-icons/md';

import { RiCouponLine } from 'react-icons/ri';
import { FiCheckCircle } from 'react-icons/fi';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';
import { IoIosRemove, IoIosAdd } from 'react-icons/io';
import { formatPrice } from '../../util/format';
import * as CartActions from '../../store/modules/cart/actions';
import * as S from './styles';
import history from '../../services/history';

function Cart({ cart, total, removeFromCart, updateAmount, defineCoupon }) {
  const [coupon, setCoupon] = useState({
    status: false,
    discount: 0,
    name: '',
  });

  const handleCoupon = (value) => {
    switch (value) {
      case '2020CM11':
        setCoupon({
          status: true,
          discount: 5 / 100,
          name: value,
        });
        break;
      default:
        setCoupon({ status: false, discount: total, name: value });
        break;
    }
    return false;
  };

  const handleFinish = () => {
    if (coupon.name.length >= 1) {
      defineCoupon(coupon.name, total - total * coupon.discount);
      // return;
    }

    history.push('/register');
  };

  return (
    <S.Container>
      {cart.length <= 0 ? (
        <S.EmptyCart>
          <MdRemoveShoppingCart />
          <div>
            <h2>Oops...</h2>
            <p>Aparentemente seu carrinho está vazio!</p>
            <S.StartShopping to="/home">Começar a comprar</S.StartShopping>
          </div>
        </S.EmptyCart>
      ) : (
        <>
          <S.Cart>
            <S.CartProductHeader>
              <span>Produto</span>
              <span>Quantidade</span>
              <span>Subtotal</span>
            </S.CartProductHeader>

            {cart.map((product) => (
              <S.CartProduct>
                <div>
                  <img src={product.image} alt={product.name} />
                  <span>{product.name}</span>
                </div>
                <S.BoxQtdProduct>
                  <S.ButtonQtdProduct
                    remove
                    onClick={() => updateAmount(product.id, product.amount - 1)}
                  >
                    <IoIosRemove size={24} />
                  </S.ButtonQtdProduct>

                  <S.QtdProduct>{product.amount}</S.QtdProduct>

                  <S.ButtonQtdProduct
                    onClick={() => updateAmount(product.id, product.amount + 1)}
                  >
                    <IoIosAdd size={24} />
                  </S.ButtonQtdProduct>
                </S.BoxQtdProduct>

                <span>{product.subtotal}</span>

                <span
                  className="delete"
                  onClick={() => removeFromCart(product.id)}
                >
                  <MdDelete size={20} color="#FF4500" />
                </span>
              </S.CartProduct>
            ))}
          </S.Cart>
          <footer>
            <div className="group-button">
              <Link to="/home">
                <S.ButtonCartEvent type="button" back>
                  Continuar Comprando
                </S.ButtonCartEvent>
              </Link>
              {/* <Link to="/register"> */}
              <S.ButtonCartEvent type="button" onClick={handleFinish}>
                Finalizar Pedido
              </S.ButtonCartEvent>
              {/* </Link> */}
            </div>

            <S.Cupom>
              <RiCouponLine size={24} color="#89bd55" />
              <S.InputCupomGroup>
                <S.InputCupom
                  type="text"
                  placeholder="Digite seu cupom de desconto"
                  onChange={(e) => handleCoupon(e.target.value)}
                />
                {coupon.name.length >= 1 && (
                  <>
                    {coupon.status ? (
                      <S.MsgCoupon status={coupon.status}>
                        <FiCheckCircle size={16} color="#89bd55" />
                        <span>Cupom válido</span>
                      </S.MsgCoupon>
                    ) : (
                      <S.MsgCoupon status={coupon.status}>
                        <MdErrorOutline size={16} color="#ff4040" />
                        <span>Cupom inválido</span>
                      </S.MsgCoupon>
                    )}
                  </>
                )}
              </S.InputCupomGroup>
            </S.Cupom>
            <S.Total>
              <span>TOTAL</span>
              <strong>
                {coupon.status
                  ? formatPrice(total - total * coupon.discount)
                  : formatPrice(total)}
              </strong>
            </S.Total>
          </footer>
        </>
      )}
    </S.Container>
  );
}

const mapStateToProps = (state) => ({
  cart: state.cart.products.map((product) => ({
    ...product,
    subtotal: formatPrice(product.price * product.amount),
  })),
  total: state.cart.products.reduce((total, product) => {
    return total + product.price * product.amount;
  }, 0),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(CartActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
