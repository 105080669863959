import produce from 'immer';

export default function store(state = {}, action) {
  switch (action.type) {
    case '@store/STORE_SUCCESS':
      return produce(state, (draft) => {
        const { store } = action;
        draft.store = store;
      });

    default:
      return state;
  }
}
