import produce from 'immer';

const INITIAL_STATE = {
  statusAddCart: false,
  statusDeliveryRoute: false,
  statusDeliveryBoy: false,
  statusOptin: false,
};

export default function modal(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@modal/TOGGLE_MODAL_ADD_CART':
      return produce(state, (draft) => {
        const { statusAddCart } = action;
        draft.statusAddCart = statusAddCart;
      });
    case '@modal/TOGGLE_MODAL_DELIVERY_ROUTE':
      return produce(state, (draft) => {
        const { statusDeliveryRoute } = action;
        draft.statusDeliveryRoute = statusDeliveryRoute;
      });
    case '@modal/TOGGLE_MODAL_DELIVERY_BOY':
      return produce(state, (draft) => {
        const { statusDeliveryBoy } = action;
        draft.statusDeliveryBoy = statusDeliveryBoy;
      });
    case '@modal/TOGGLE_MODAL_OPTIN':
      return produce(state, (draft) => {
        const { statusOptin } = action;
        draft.statusOptin = statusOptin;
      });
    default:
      return INITIAL_STATE;
  }
}
