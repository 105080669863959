import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  background-color: #fafafa;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);
`;

export const Content = styled.div`
  margin-top: 10px;
  padding: 10px 0px 50px;

  > h3 {
    font-size: 28px;
    text-align: center;
  }

  button {
    float: right;
    background: #ffc300;
    color: #191919;
    border: 0;
    border-radius: 4px;
    overflow: hidden;
    margin-top: auto;
    display: flex;
    align-items: center;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, '#ffc300')};
    }
    div {
      display: flex;
      align-items: center;
      padding: 12px;
      background: rgba(29, 138, 155, 0.2);
      svg {
        margin-right: 5px;
      }
    }
    span {
      flex: 1;
      text-align: center;
      font-weight: bold;
    }
  }
`;

export const Divisor = styled.div`
  flex: 1;
  padding-left: 30%;
  > h3 {
    font-size: 24px;
    text-align: center;
    font-weight: 400;
    width: 50%;

    font-style: normal;

    border-bottom-color: #3333;
    font-weight: 600;
    border-bottom: 2px solid;
    padding-bottom: 7px;
    padding-right: 10px;
    margin-top: 21px;
    margin-bottom: 11px;
    line-height: 1.1;
    box-sizing: border-box;
  }
`;

export const Presentation = styled.div`
  /* display: flex; */
  align-items: center;
  margin: 30px;

  .itens {
    margin-top: 10px;
  }

  figure {
    text-align: center;
    display: table;

    img {
      width: 240px;
      border-radius: 8px;
    }

    figcaption {
      font: 14px 'Source Sans Pro', sans-serif;
      -webkit-font-smoothing: antialiased;
      color: #333;
      padding: 15px;
      display: table-caption;
      caption-side: bottom;
    }
  }

  label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
  }
`;

export const Description = styled.div`
  padding: 2% 16% 8%;
  p {
    text-align: center;
    text-justify: auto;
    font-size: 16px;
  }
`;
