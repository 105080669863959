import React, { useRef, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Form } from '@unform/web';

import { Link } from 'react-router-dom';
import { Container, Content, Divisor, Presentation } from './styles';
import Radio from '../../components/Form/Radio';
import Checkbox from '../../components/Form/Checkbox';
import api from '../../services/api';
import { formatPrice } from '../../util/format';

import * as CartActions from '../../store/modules/cart/actions';

class Details extends Component {
  state = {
    product: {},
    additionals: [],
  };

  async componentDidMount() {
    const { id } = this.props.match.params;
    const additionals = await api.get(`additionals`);

    const response = await api.get(`product/${id}`);

    const formattedPrice = formatPrice(response.data.price);

    const data = { ...response.data, formattedPrice };

    this.setState({ product: data });
    this.setState({ additionals: additionals.data });
  }

  handleAddProduct = (id) => {
    const { addToCartRequest } = this.props;

    addToCartRequest(id);
  };

  testChange = (e) => {
    console.log(e.target);
  };

  render() {
    const hrStyle = {
      border: '0',
      height: '0',
      borderTop: '1px solid rgba(0, 0, 0, 0.1)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
      marginTop: '10px',
    };

    const presentationStyle = {
      display: 'flex',
      alignItems: 'center',
    };

    const btnCarrinho = {
      marginTop: '15px',
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '5px',
      paddingRight: '5px',
    };

    const { product, additionals } = this.state;

    const additionalItens = [
      {
        title: 'Carne',
        name: 'meat',
        options: [
          { id: 'mal', label: 'Mal passada' },
          { id: 'ponto', label: 'Ao Ponto' },
          { id: 'bem', label: 'Bem passada' },
        ],
      },
      {
        title: 'Molho',
        name: 'suace',
        options: [
          { id: '1', label: 'Molho Inglês' },
          { id: '2', label: 'Mostarda Defumada' },
        ],
      },
    ];
    // const additionalOptions = [
    //   { id: 'mol_ingles', value: 'mol_ingles', label: 'Molho Inglês' },
    //   { id: 'most_def', value: 'most_def', label: 'Mostarda Defumada' },
    // ];
    const initial = { meat: 'ponto', sauce: '1', adds: ['barb_whis'] };

    return (
      <Container>
        <Content>
          <h3>{product.name}</h3>
          <hr style={hrStyle} />
          {/* <div> */}
          <Presentation style={presentationStyle}>
            <figure>
              <img src={product.image} alt={product.name} />
              <figcaption>
                <small>{product.description}</small>
              </figcaption>
            </figure>
            <Divisor>
              <h2>Lista Opcionais</h2>

              <Form initialData={initial}>
                {additionalItens.map((item) => (
                  <div className="itens" key={item.name}>
                    <h4 style={{ marginTop: '10px' }}>{item.title}</h4>
                    <Radio
                      style={{ marginTop: '10px' }}
                      name={item.name}
                      options={item.options}
                    />
                  </div>
                ))}

                <div className="itens">
                  <h4 style={{ paddingTop: '10px' }}>Aditivios</h4>

                  {/* <Checkbox name="adds" options={additionals} /> */}
                  {additionals.map((add) => (
                    <div>
                      <input
                        value={add.name}
                        type="checkbox"
                        onChange={(e) =>
                          this.testChange({
                            target: {
                              name: e.target.value,
                              value: e.target.checked,
                            },
                          })
                        }
                      />
                      <label>{add.name}</label>
                    </div>
                  ))}
                </div>
              </Form>
            </Divisor>

            <div>
              <h3>Preço: {product.formattedPrice}</h3>
              <Link to="/cart">
                <button
                  style={btnCarrinho}
                  type="button"
                  onClick={() => this.handleAddProduct(product.id)}
                >
                  <h4>ADICIONAR AO CARRINHO</h4>
                </button>
              </Link>
            </div>
          </Presentation>
          {/* </div> */}
        </Content>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(CartActions, dispatch);

export default connect(null, mapDispatchToProps)(Details);
