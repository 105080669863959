import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-modal';
import * as S from './styles';
import Menu from '../../components/Admin/Menu';

import Board from '../../components/Admin/Board';
import Statistics from '../../components/Admin/Statistics';
import Products from '../../components/Admin/Products';
import Additional from '../../components/Admin/Additional';
import Categories from '../../components/Admin/Categories';

const Admin = ({ status }) => {
  const handleChangeViews = () => {
    switch (status) {
      case 'statusPedido':
        return <Board />;
      case 'estatistica':
        return <Statistics />;
      // case 'categoria':
      //   return <Categories />;
      // case 'produto':
      //   return <Products />;
      // case 'adicionais':
      //   return <Additional />;
      default:
        break;
    }
  };

  return (
    <S.Container>
      <Menu />
      {handleChangeViews()}
    </S.Container>
  );
};

const mapStateToProps = (state) => ({
  status: state.admin.menu.status,
});

export default connect(mapStateToProps, null)(Admin);
