export function makeStoreRequest(store) {
  return {
    type: '@store/STORE_PREPARE',
    store,
  };
}

export function makeStoreSuccess(store) {
  return {
    type: '@store/STORE_SUCCESS',
    store,
  };
}
