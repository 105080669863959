export function addToCartRequest(id, amount) {
  return {
    type: '@cart/ADD_REQUEST',
    id,
    amount,
  };
}

export function addToCartSuccess(product) {
  return {
    type: '@cart/ADD_SUCCESS',
    product,
  };
}

export function removeFromCart(id) {
  return {
    type: '@cart/REMOVE',
    id,
  };
}

export function updateAmount(id, amount) {
  return {
    type: '@cart/UPDATE_AMOUNT',
    id,
    amount,
  };
}

export function defineCoupon(coupon, newValue) {
  return {
    type: '@cart/COUPON',
    coupon,
    newValue,
  };
}

export function changeObservation(observation) {
  return {
    type: '@cart/CHANGE_OBSERVATION',
    observation,
  };
}

export function updateCartAux() {
  return {
    type: '@cart/UPDATE_AUX_CART',
  };
}

export function clearCart() {
  return {
    type: '@cart/CLEAR',
  };
}
