import React from 'react';
import { isMobile } from 'react-device-detect';

import { Container } from './styles';

function Footer() {
  const urlMobile = 'https://api.whatsapp.com/send?phone=5511933517092';
  const urlDesktop = 'https://web.whatsapp.com/send?phone=5511933517092';

  return (
    <Container>
      <footer>
        <div>
          <strong>
            <b>2Bebot</b> © 2020 - Todos os direitos reservados
          </strong>
          <ul>
            <li>
              <a href="./" rel="noopener noreferrer">
                Mudar de Loja
              </a>
              <a
                href={isMobile ? urlMobile : urlDesktop}
                target="_blank"
                rel="noopener noreferrer"
              >
                Entre em contato
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </Container>
  );
}

export default Footer;
