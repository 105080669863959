import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as StoreActions from '../../store/modules/store/actions';

import * as S from './styles';
import api from '../../services/api';

function Landpage({ makeStoreRequest }) {
  const [inputSearch, setInputSearch] = useState('');
  const [stores, setStores] = useState([]);
  const [filteredStore, setFilteredStores] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`stores`);
      setStores(response.data);
      setFilteredStores(response.data);
      setLoading(true);
    }

    fetchData();
  }, []);

  const handleSearch = (event) => {
    const input = event.target.value;
    setInputSearch(input);
    if (input.length === 0) return setFilteredStores([...stores]);

    const filter = stores.filter((item) =>
      item.name.toLowerCase().includes(input.toLowerCase())
    );

    setFilteredStores([...filter]);
    return null;
  };

  const handleClick = (store) => {
    makeStoreRequest(store);
  };

  return (
    <S.Container>
      <S.Header>
        <S.Title>Escolha a sua cidade</S.Title>
        <S.Search>
          <input
            type="text"
            placeholder="Sua cidade aqui"
            value={inputSearch}
            onChange={handleSearch}
          />
        </S.Search>
      </S.Header>
      <div>
        {loading &&
          filteredStore.map((store) => (
            <S.Store onClick={() => handleClick(store)}>
              <h3>{store.name}</h3>
              <span>
                {store.StoreAddress.city} - {store.StoreAddress.district}
              </span>
              <br />
              <span>{store.StoreAddress.street}</span>
            </S.Store>
          ))}
      </div>
    </S.Container>
  );
}

const mapStateToProps = (state) => ({
  store: state.store,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(StoreActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Landpage);
