import produce from 'immer';

const INITIAL_STATE = {
  products: [],
};

export default function cart(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@cart/ADD_SUCCESS':
      return produce(state, (draft) => {
        const { product } = action;

        draft.products.push(product);
      });

    case '@cart/REMOVE':
      return produce(state, (draft) => {
        const productIndex = draft.products.findIndex(
          (p) => p.id === action.id
        );

        if (productIndex >= 0) {
          draft.products.splice(productIndex, 1);
        }
      });

    case '@cart/CLEAR':
      return produce(state, (draft) => {
        draft.productsSold = [];
        draft.productsSold = draft.products;
        draft.products = [];
        draft.newValue = action.newValue;
        draft.coupon = action.coupon;
      });

    case '@cart/UPDATE_AUX_CART':
      return produce(state, (draft) => {
        draft.productsSold = [];
        draft.productsSold = draft.products;
      });

    case '@cart/CHANGE_OBSERVATION':
      return produce(state, (draft) => {
        const { observation } = action;
        draft.observation = observation;
      });

    case '@cart/UPDATE_AMOUNT': {
      return produce(state, (draft) => {
        const { id, amount } = action;

        const productIndex = draft.products.findIndex((p) => p.id === id);

        if (productIndex >= 0) {
          if (amount === 0) {
            draft.products.splice(productIndex, 1);
          } else draft.products[productIndex].amount = Number(amount);
        }
      });
    }

    case '@cart/COUPON': {
      return produce(state, (draft) => {
        draft.newValue = action.newValue;
        draft.coupon = action.coupon;
      });
    }

    default:
      return state;
  }
}
