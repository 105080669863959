import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import * as S from './styles';
import { formatPrice } from '../../util/format';
import * as ModalActions from '../../store/modules/modal/actions';
import ModalOptin from '../../components/ModalOptin';
import api from '../../services/api';
import * as OrderActions from '../../store/modules/order/actions';
import { clearCart } from '../../store/modules/cart/actions';

function Receipt({
  cart,
  total,
  order,
  client,
  onToggleModalOptin,
  statusModalOptin,
  makeOrderRequest,
  newDiscount,
}) {
  let clientPhone = '';
  if (client && client.client) {
    clientPhone = client.client.phone_number.slice(2);
  }

  useEffect(() => {
    console.log(order);
  }, [order]);

  let dateStamp = '';
  if (order && order.order) {
    dateStamp = `${+new Date(order.order.createdAt)}`.substring(6, 13);
  }

  const handleToggleModalOptin = async () => {
    const urlMobile = `https://api.whatsapp.com/send?text=Oi%2C+eu+gostaria+de+receber+notifica%C3%A7%C3%B5es+da+Dominos+Cambuí.&phone=5511933517092`;
    const urlDesktop = `https://web.whatsapp.com/send?text=Oi%2C+eu+gostaria+de+receber+notifica%C3%A7%C3%B5es+da+Dominos+Cambuí.&phone=5511933517092`;
    try {
      await api.get(`/cellphone/${clientPhone}`);
      window.open(isMobile ? urlMobile : urlDesktop);
    } catch (err) {
      onToggleModalOptin(!statusModalOptin);
    }
  };

  const handleMakeOrder = async () => {
    const response = await api.post('/client', order.order.client);
    makeOrderRequest(
      order.order.cart,
      response.data,
      order.order.store,
      order.order.deliverType === 'Entrega' ? 1 : 2,
      order.order.deliverType !== 'Entrega' ? null : order.order.paymentType,
      order.order.paymentType !== 'Dinheiro' ? null : order.order.changeTo,
      newDiscount || total
    );
    clearCart();
  };

  return (
    <S.CardReceipt>
      {statusModalOptin && <ModalOptin />}
      <S.CardReceiptHeader>
        <S.CardReceiptNumberOrder>
          {' '}
          Pedido
          {/* - {dateStamp}{' '} */}
        </S.CardReceiptNumberOrder>
        {/* <S.LinkWhatsapp
          href={`https://clique-aqui.xyz/cm11/${clientPhone}`}statusModalOptin
          target="_blank"
          rel="noopener noreferrer"
        >
          Acompanhe o seu pedido pelo WhatsApp
        </S.LinkWhatsapp> */}
        {/* <S.LinkWhatsapp onClick={() => handleToggleModalOptin()}>
          Acompanhe o seu pedido pelo WhatsApp
        </S.LinkWhatsapp> */}
        {order.order?.client?.name && (
          <div className="resume">
            <span>Cliente: {order.order?.client.name}</span>
            <span>
              Endereço: {order.order?.client.address},{' '}
              {order.order?.client.house_number} -{' '}
              {order.order?.client.district}
            </span>
            <span>Celular: {order.order?.client.phone_number}</span>
            <span>Pagamento: {order.order.paymentType}</span>
          </div>
        )}
      </S.CardReceiptHeader>

      <S.CardReceiptMain>
        <S.CardReceiptMainHeader>
          <span>Produto</span>
          <span>Quantidade</span>
          <span>Total</span>
        </S.CardReceiptMainHeader>
        {cart.length >= 1 ? (
          cart.map((item) => (
            <S.CardReceiptMainProduct>
              <div>
                <img src={item.image} alt={item.name} />
                <span>{item.name}</span>
              </div>
              <span>{item.amount}</span>
              <span>{formatPrice(item.price)}</span>
            </S.CardReceiptMainProduct>
          ))
        ) : (
          <div className="receipt-not-product">Não possui nenhum produto</div>
        )}
        <S.CardReceiptInfo>
          <S.CardReceiptInfoHeader>
            <span>Subtotal</span>
            <span>Frete</span>
            <span>Total</span>
          </S.CardReceiptInfoHeader>

          <S.CardReceiptInfoContent>
            <span>{formatPrice(newDiscount || total)}</span>
            <span>{formatPrice(6)}</span>
            <span>
              {formatPrice(newDiscount ? newDiscount + 6 : total + 6)}
            </span>
          </S.CardReceiptInfoContent>
        </S.CardReceiptInfo>

        <S.CardReceiptConfirmation>
          <S.ButtonCartEvent type="button" onClick={handleMakeOrder}>
            Confirmar Pedido
          </S.ButtonCartEvent>
        </S.CardReceiptConfirmation>
      </S.CardReceiptMain>
    </S.CardReceipt>
  );
}

const mapStateToProps = (state) => ({
  client: state.client,
  order: state.order,
  cart: state.cart.productsSold.map((product) => ({
    ...product,
    subtotal: formatPrice(product.price * product.amount),
  })),
  total: state.cart.productsSold.reduce((total, product) => {
    return total + product.price * product.amount;
  }, 0),
  statusModalOptin: state.modal.statusOptin,
  newDiscount: state.cart.newValue,
});

const mapDispatchToProps = (dispatch) => ({
  onToggleModalOptin: (statusModalOptin) =>
    dispatch(ModalActions.setModalToggleOptin(statusModalOptin)),
  makeOrderRequest: (
    cart,
    client,
    store,
    deliverType,
    paymentType,
    changeTo,
    newValue
  ) =>
    dispatch(
      OrderActions.makeOrderRequest(
        cart,
        client,
        store,
        deliverType,
        paymentType,
        changeTo,
        newValue
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Receipt);
