import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  padding: 30px;
  background: #fbfbfb;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);

  h1 {
    text-align: center;
  }

  select {
    margin-bottom: 10px;
  }

  div {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 520px) {
      flex-direction: column;
    }

    button {
      background-color: #ffc300;
      color: #191919;
      border: 0;
      border-radius: 8px;
      padding: 12px 20px;
      box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);
      margin-left: 1%;
      margin-right: 2%;
      margin-top: 1%;
      font-weight: bold;
      text-transform: uppercase;
      transition: background 180ms ease-in-out;
      margin-left: 10px;

      @media (max-width: 520px) {
        margin-top: 20px;
        width: 100%;
        padding: 20px;
      }

      &:hover {
        background-color: ${darken(0.1, '#ffc300')};
        box-shadow: 0 1px 4px 0 rgba(49, 49, 49, 0.8);
      }
    }
  }
`;

export const WhatsDiv = styled.div`
  text-align: center;
  .whatsApp {
    background-color: #25d366;
    box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);
    /* margin-left: 43%; */

    &:hover {
      background-color: ${darken(0.1, '#25d366')};
      box-shadow: 0 1px 4px 0 rgba(49, 49, 49, 0.8);
    }
  }
`;
