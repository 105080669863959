import styled from 'styled-components';
import { ColorText } from '../../../styles/variables';

export const Menu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 0px;
  padding: 15px 30px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-weight: 500;
`;

export const MenuItem = styled.span`
  color: ${ColorText};
  font-size: 16px;
  display: block;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &::after {
    content: '';
    width: 10px;
    height: 10px;
    background: #28a745;
    border-radius: 50%;
    display: ${(props) => (props.active ? 'inline-block' : 'none')};
    margin-left: 10px;
  }
`;
