import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  border-radius: 4px;
  background: #f2f3f4;
  box-shadow: 0px 4px 5px 2px #cdcdcd;
  padding: 30px 5px;
  text-align: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  margin: 5px auto;
`;

export const Title = styled.h3`
  font-size: 1.2rem;
`;

export const Search = styled.div`
  label {
    margin: 0 0 0.3125rem;
    color: #768093;
    font-size: 0.875rem;
    font-weight: 400;
  }
  input {
    width: 32%;
    margin: auto;
    text-align: center;

    border: 1px solid #dbdbdb;
    border-radius: 4px;
    background-color: #fff;
    padding: 12px 15px !important;
  }
`;

export const Store = styled.div`
  width: 30%;
  margin: auto;
  padding: 0.4rem 10px;
  margin-bottom: 8px;

  border-radius: 4px;

  text-align: center;

  background: #fff;
  box-shadow: 0px 4px 5px 2px #cdcdcd;

  &:hover {
    background: ${darken(0.2, '#fff')};
  }
`;
