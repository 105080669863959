import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

export const ContainerModal = styled.div`
  z-index: 999;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;

  @media (max-width: 768px) {
    transform: initial;
    overflow-y: scroll;
  }
`;

export const ContentModal = styled.div`
  position: absolute;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 0%,
    transparent
  );
`;

export const CardModal = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 500px;
  min-width: 500px;
  max-height: 600px;
  overflow-y: scroll;
  /* padding: 30px 20px; */
  border-radius: 4px;
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.3);
  transform: translateY(5%);

  @media (max-width: 768px) and (min-width: 500px) {
    width: 80%;
    min-width: 80%;
    position: absolute;
    top: 50px;
  }

  @media (max-width: 499px) {
    width: 95%;
    min-width: 95%;
    margin: 0 auto;
    position: absolute;
    top: 5%;
  }
`;

export const ButtonClose = styled.div`
  width: 50px;
  height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  right: 33%;
  top: 3%;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.6;
  }
`;

export const CardModalHeader = styled.div`
  padding: 0px;
  max-height: 300px;
  height: 300px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 4px 4px 0px 0px;
  }
`;

export const CardTitleProduct = styled.p`
  padding: 15px;
  color: #717171;
  font-size: 16px;
  line-height: 22px;
`;

export const Loading = styled.div`
  width: 23px;
  height: 23px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  span {
    display: block;
    width: 60px;
    height: 60px;
    border: 3px solid transparent;
    border-radius: 50%;
    border-right-color: #fff;
    animation: spinner-animated 0.8s linear infinite;
  }

  @keyframes spinner-animated {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const CardModalOptions = styled.div``;

export const CardModalOptionsHeader = styled.div`
  padding: 15px;
  background: #3f3e3e;
  display: flex;
  justify-content: space-between;

  span {
    color: #fff;
    display: block;
    line-height: 24px;
  }

  div {
    span:nth-child(1) {
      font-size: 18px;
      font-weight: 500;
    }

    span:nth-child(2) {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .required {
    align-self: flex-end;
    font-weight: 700;
  }
`;

export const CardModalOptionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;
  transition: background 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    background: #ececec;
  }
  span {
    display: inline-block;
    font-size: 16px;
  }
`;

export const GroupArea = styled.div`
  padding: 15px 15px 0px 15px;
  textarea {
    width: 100%;
    height: 70px;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 3px;
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.06);
    padding: 10px;
    font-size: 16px;
    color: #3f3e3e;
    font-weight: 500;
    -webkit-appearance: none;
    resize: none;
  }
`;

export const CardModalOptionsFooter = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const WrapperGroupFooter = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }

  .QtdProductsTitle {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    color: #3f3e3e;
  }
`;

export const BoxQtdProduct = styled.div`
  display: flex;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #3f3e3e;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonQtdProduct = styled.span`
  padding: 10px;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  display: block;

  background: #ececec;

  &:hover {
    background: ${darken(0.06, '#ececec')};
  }
`;

export const QtdProduct = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  min-width: 100px;
  width: 100px;
  text-align: center;
  padding: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonAddToCart = styled.span`
  background: #28a745;
  padding: 15px 80px;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  align-self: flex-end;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 15px;
  }

  &:hover {
    background: ${darken(0.04, '#28a745')};
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: ${(props) => (props.check ? '#28a745' : 'white')};
  border: 1px solid ${(props) => (props.check ? 'transparent' : '#ddd')};
  border-radius: 3px;
  transition: all 150ms;
  cursor: pointer;
  margin-right: 10px;

  ${Icon} {
    visibility: ${(props) => (props.check ? 'visible' : 'hidden')};
  }
`;

export const Checkbox = ({ className, check, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox check={check} {...props} />
    <StyledCheckbox check={check}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);
