import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  position: sticky;
  top: 0;
  width: 100%;
  background: #fafafa;
  border-radius: 4px;
  box-shadow: 0px 4px 5px 2px #cdcdcd;
  padding: 10px 10px 10px 10px;

  > a figure img {
    width: 100%;
    max-width: 275px;
    @media (max-width: 430px) {
      width: 130px;
    }
  }
`;

export const Cart = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: opacity 150ms ease-in-out;
  position: relative;

  @media (max-width: 430px) {
    margin-left: 16px;
  }

  &:hover {
    opacity: 0.7;
  }

  & div {
    text-align: right;
    margin-right: 10px;

    @media (max-width: 550px) {
      display: none;
    }

    & strong {
      display: block;
      font-size: 16px;
      color: #313131;
    }

    & span {
      font-size: 12px;
      color: #313131;
    }
  }

  > span:last-child {
    background: #ffb900;
    color: #191919;
    font-size: 11px;
    line-height: 20px;
    text-align: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    position: absolute;
    right: -2px;
    top: -6px;
    display: none;
    @media (max-width: 430px) {
      display: initial;
    }
  }
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;

  a {
    color: rgb(102, 102, 102);
    text-decoration: none;
  }

  @media screen and (max-width: 550px) {
    visibility: hidden;
    clear: both;
    display: none;
  }
  span {
    display: flex;
    align-items: center;
    color: #3f3e3e;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    svg {
      margin-right: 10px;
      /* color: #4fce5d; */
    }
  }
`;

export const HeaderInfoWpp = styled.div`
  flex-direction: column;
  visibility: hidden;
  clear: both;
  display: none;

  a {
    color: #3f3e3e;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    svg {
      margin-right: 7px;
      /* color: #4fce5d; */
    }
  }

  @media screen and (max-width: 550px) {
    visibility: visible;
    clear: unset;
    display: flex;
  }
`;
