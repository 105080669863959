import produce from 'immer';

const INITIAL_STATE = {
  menu: {
    status: 'statusPedido',
    active: true,
  },
};

export default function admin(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@modal/TOGGLE_SET_MENU_STATUS':
      return produce(state, (draft) => {
        const { menuStatus } = action;
        draft.menu.status = menuStatus;
      });
    default:
      return INITIAL_STATE;
  }
}
