import React from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import { IoLogoWhatsapp, IoMdPin } from 'react-icons/io';
import { MdShoppingCart } from 'react-icons/md';

import * as S from './styles';

import logo from '../../assets/images/novaLogo.png';

function Header({ cartSize }, props) {
  const path = window.location.pathname;
  const adminRegex = /(\admin[#?/]?)$/;

  const withoutCart = () => {
    return (
      <S.Container>
        <Link to="/home">
          <figure>
            <img src={logo} alt="Delivery 2B" />
          </figure>
        </Link>

        <S.HeaderInfo>
          <span>
            <a
              href="https://wa.me/5511933517092"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IoLogoWhatsapp color="#4fce5d" />
              Telefone: +55 11 93351-7092
            </a>
          </span>
          <span>
            <IoMdPin color="#3f3e3e" />
            Alameda Santos 415 Conjunto 101 São Paulo - Cerqueira César SP
            01419-000 BR
          </span>
        </S.HeaderInfo>

        <S.HeaderInfoWpp>
          <a
            href="https://wa.me/5511933517092"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoWhatsapp size={18} color="#4fce5d" />
            +55 11 93351-7092
          </a>
        </S.HeaderInfoWpp>

        {/* <S.Cart to="/cart">
            <div>
              <strong>Meu carrinho</strong>
              <span>
                {cartSize === 1 ? `${cartSize} produto` : `${cartSize} produtos`}
              </span>
            </div>
            <MdShoppingCart size={32} color="#313131" />
            <span>{cartSize}</span>
          </S.Cart> */}
      </S.Container>
    );
  };

  const withCart = () => {
    return (
      <S.Container>
        <Link to="/home">
          <figure>
            <img src={logo} alt="Delivery 2B" />
          </figure>
        </Link>

        <S.HeaderInfo>
          <span>
            <a
              href="https://wa.me/5511933517092"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IoLogoWhatsapp color="#4fce5d" />
              Telefone: +55 11 93351-7092
            </a>
          </span>
          <span>
            <IoMdPin color="#3f3e3e" />
            Alameda Santos 415 Conjunto 101 São Paulo - Cerqueira César SP
            01419-000 BR
          </span>
        </S.HeaderInfo>

        <S.HeaderInfoWpp>
          <a
            href="https://wa.me/5511933517092"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoWhatsapp size={18} color="#4fce5d" />
            +55 11 93351-7092
          </a>
        </S.HeaderInfoWpp>

        <S.Cart to="/cart">
          <div>
            <strong>Meu carrinho</strong>
            <span>
              {cartSize === 1 ? `${cartSize} produto` : `${cartSize} produtos`}
            </span>
          </div>
          <MdShoppingCart size={32} color="#313131" />
          <span>{cartSize}</span>
        </S.Cart>
      </S.Container>
    );
  };

  function newHeader() {
    if (!path.match(adminRegex)) return withCart();
    return withoutCart();
  }

  return <>{newHeader(cartSize)}</>;
}

export default connect((state) => ({
  cartSize: state.cart.products.length,
}))(Header);
