import React, { useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, WhatsDiv } from './styles';

import * as OrderActions from '../../store/modules/order/actions';
import { formatPrice } from '../../util/format';

function PaymentChoice({ cart, client, store, makeOrderRequest }) {
  const [payment, setPayment] = useState('Dinheiro');
  const [changeTo, setChangeTo] = useState(null);
  const getPaymentType = () => {
    if (payment === 'Dinheiro' && !changeTo)
      toast.error('Você precisa informar o troco');
    else makeOrderRequest(cart, client, store, 1, payment, changeTo);
  };

  return (
    <Container>
      <h1>Como deseja pagar?</h1>

      <div>
        <select
          name="cars"
          id="cars"
          value={payment}
          onChange={(e) => {
            if (e.target.value === 'Cartão de Crédito/Débito')
              setChangeTo(null);
            setPayment(e.target.value);
          }}
        >
          <option value="Dinheiro">Dinheiro</option>
          <option value="Cartão de Crédito/Débito">
            Cartão de Crédito/Débito
          </option>
        </select>
        {payment === 'Dinheiro' && (
          <input
            type="text"
            placeholder="Troco para?"
            value={changeTo}
            onChange={(e) => setChangeTo(e.target.value)}
          />
        )}
        <button type="button" onClick={() => getPaymentType()}>
          Avançar
        </button>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  cart: state.cart.products.map((product) => ({
    ...product,
    subtotal: formatPrice(product.price * product.amount),
  })),
  total: formatPrice(
    state.cart.products.reduce((total, product) => {
      return total + product.price * product.amount;
    }, 0)
  ),
  client: state.client,
  store: state.store.store,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(OrderActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentChoice);
