import styled from 'styled-components';
import { Form } from '@unform/web';
import { darken } from 'polished';

export const Container = styled.div`
  height: 100%;
  /* flex-grow: 2; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  background-color: #fafafa;
  border-radius: 4px;
  padding: 50px;
  transition: 0.2s ease-in;
  @media (max-width: 520px) {
    padding: 20px;
  }

  border: 1px solid;
  border-color: rgba(25, 25, 25, 0.2);
  box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);
  width: 100%;
`;

export const FormStyled = styled(Form)`
  padding: 0px 10px;
  input {
    padding: 0.5em;
    margin: 0.5em;
    color: ${(props) => props.inputColor || '#191919'};
    /* background: '#2D2D37'; */
    background-color: #dddddd;
    box-shadow: 0 1px 4px 0 rgba(221, 221, 221, 0.8);
    border: none;
    border-radius: 4px;
    width: 100%;
    transition: all 100ms;
    &:hover {
      border-color: hsl(10, 20%, 70%);
    }
  }

  span.error {
    color: #e74c3c;
    margin-top: 4px;
  }

  button {
    margin-left: 10px;
    background: #ffc300;
    color: #191919;
    border: 0;
    border-radius: 4px;
    padding: 12px 20px;
    font-weight: bold;
    text-transform: uppercase;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.03, '#ffc300')};
    }
  }
`;

export const Columns = styled.div`
  flex-direction: row;
  display: flex;

  column-count: 2;
  @media (max-width: 520px) {
    column-count: 0;
  }
  /* justify-content: center;
  align-items: center; */
`;

export const SelectStyled = styled.select`
  padding: 10px 10px;
  border-radius: 4px;
  border: 1px transparent;
  margin-bottom: 10px;
  margin-left: 5px;
`;
