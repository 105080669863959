import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import Cart from './pages/Cart';
import Details from './pages/Details';
import Admin from './pages/Admin';
import Register from './pages/Register';
import Receipt from './pages/Recepit';
import DeliveryChoice from './pages/DeliveryChoice';
import PaymentChoice from './pages/PaymentChoice';
import Landpage from './pages/Landpage';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Landpage} />
      <Route path="/home" exact component={Home} />
      <Route path="/cart" component={Cart} />
      <Route path="/details/:id" component={Details} />
      <Route path="/admin" component={Admin} />
      <Route path="/register" component={Register} />
      <Route path="/receipt" component={Receipt} />
      <Route path="/delivery-choice" component={DeliveryChoice} />
      <Route path="/payment-choice" component={PaymentChoice} />
    </Switch>
  );
}
