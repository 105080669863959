import styled from 'styled-components';
import { darken } from 'polished';
import { Link } from 'react-router-dom';

// export const Wrapper = styled.section`

// `;

export const ItemDescription = styled.div`
  width: 10000px;
  zoom: 1;
  overflow: hidden;
  display: table-cell;
  vertical-align: top;
`;

export const ItemName = styled.div`
  font-size: 24px;
  font-weight: 300;
  vertical-align: top;
  padding-right: 4px;
  overflow: hidden;
  position: relative;
  color: #191919;
`;

export const ItemDetails = styled.div`
  font-size: 14px;
  font-weight: lighter;
  letter-spacing: 0.2px;
  line-height: 1.4;
  height: 40px;
  word-wrap: break-word;
  overflow: hidden;
  white-space: pre-line;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: 'Source Sans Pro', sans-serif;
  color: #191919;
`;

export const ItemPrice = styled.div`
  font-size: 24px;
  font-weight: 400;
  position: relative;
  color: #191919;
`;

export const ItemOrder = styled.div`
  font-size: 11px;
  font-weight: 300;
  position: relative;
  /* color: #23262e; */
  color: #191919;
`;

export const Item = styled.button`
  &:first-child {
    margin-top: 0;
  }

  text-decoration: none;

  display: block;
  zoom: 1;
  overflow: hidden;
  padding: 8px;
  border-bottom: 2px solid transparent;
  transition: border 0.15s, background 0.15s;
  text-decoration: none;
`;

export const Container = styled.div`
  margin-top: 0;
  margin-bottom: 11px;
  box-sizing: border-box;

  margin: 8px auto 35px;
  width: 100%;

  display: list-item;
  text-align: -webkit-match-parent;
  list-style-type: none;
  list-style-position: inside;

  li {
    h3 {
      font-size: 36px;
      font-weight: 400;
      font-style: normal;
      color: #191919;

      border-bottom-color: #191919;
      font-weight: 600;
      border-bottom: 2px solid;
      padding-bottom: 7px;
      margin-top: 21px;
      margin-bottom: 11px;
      line-height: 1.1;
      box-sizing: border-box;

      text-align: -webkit-match-parent;
      list-style-type: none;

      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }

    li {
      &:hover {
        background-color: ${darken(0.1, '#f6f6f6')};
      }

      transition: background 0.2s;

      display: inline-block;
      vertical-align: top;
      margin: 0;
      box-sizing: border-box;
      margin-bottom: 8px;
      margin-left: 6px;
      background-color: #f6f6f6;
      border: 1px solid;
      border-color: rgba(25, 25, 25, 0.12);
      border-radius: 4px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
      width: 49%;

      @media only screen and (max-width: 1200px) {
        width: 49%;
      }
      @media only screen and (max-width: 992px) {
        width: 49%;
      }
      /* Small Devices, Tablets */
      @media only screen and (max-width: 768px) {
        width: 49%;
      }
      @media only screen and (max-width: 480px) {
        width: 100%;
      }
      /* Custom, iPhone Retina */
      @media only screen and (max-width: 320px) {
        width: 100%;
      }
    }
  }

  img {
    max-width: 100px;
    border-radius: 8px;
  }
`;
