import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as S from './styles';
import api from '../../services/api';
import { formatPrice } from '../../util/format';
import ModalAddCart from '../../components/ModalAddCart';
import * as ModalActions from '../../store/modules/modal/actions';

const Home = ({ onToggleModalAddCart, statusModalAddCart, store }) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [productSelected, setProductSelected] = useState({});

  useEffect(() => {
    async function fetchData() {
      const responseProducts = await api.get(
        `store/${store && store.id}/products`
      );

      const data = responseProducts.data.map((product) => ({
        ...product.Product,
        priceFormatted: formatPrice(product.Product.price),
      }));

      const responseCategories = await api.get('categories');

      setProducts(data);
      setCategories(responseCategories.data);
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (statusModalAddCart) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [statusModalAddCart]);

  const handleToggleModalAddCart = (product) => {
    onToggleModalAddCart(!statusModalAddCart);
    setProductSelected(product);
  };

  const renderItem = (product, categoryID) => {
    if (product.category === categoryID) {
      return (
        <S.CardItem
          key={`product-${product.id}`}
          onClick={() => handleToggleModalAddCart(product)}
        >
          <S.ItemImage>
            <img src={product.image} alt={product.name} />
          </S.ItemImage>
          <S.ItemDescription>
            <S.ItemName>{product.name}</S.ItemName>
            <S.ItemDetails>{product.description}</S.ItemDetails>
            <S.ItemPrice>{product.priceFormatted}</S.ItemPrice>
          </S.ItemDescription>
        </S.CardItem>
      );
    }
  };

  return (
    <>
      {statusModalAddCart && <ModalAddCart id={productSelected.id} />}
      <S.Container>
        {categories.map((category) => (
          <div key={`category-${category.id}`}>
            <h3>{category.name}</h3>
            <S.WrapperCard>
              {products.map((product) => renderItem(product, category.id))}
            </S.WrapperCard>
          </div>
        ))}
      </S.Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  statusModalAddCart: state.modal.statusAddCart,
  store: state.store.store,
});

const mapDispatchToProps = (dispatch) => ({
  onToggleModalAddCart: (statusModalAddCart) =>
    dispatch(ModalActions.setModalToggleAddCart(statusModalAddCart)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
