import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';

import history from '../../../services/history';
import { makeStoreSuccess } from './actions';

function* makeStore({ store }) {
  try {
    const response = yield call(api.get, `store/${store.id}`);
    yield put(makeStoreSuccess(response.data));
    toast.success('Sucesso');
    history.push('/home');
  } catch (e) {
    toast.error('Erro');
  }
}

export default all([takeLatest('@store/STORE_PREPARE', makeStore)]);
