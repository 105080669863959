import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);
  border-radius: 8px;
  padding: 16px 12px;
`;

export const Table = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  div {
    padding: 0;
    border: none;
    box-shadow: none;
    background: 0 0;
  }
`;

export const TableContainer = styled.div`
  position: relative;
  height: 460px;
`;

export const TableHeader = styled.div`
  padding: 14px 16px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: -0.84px;
  color: #191919;

  span {
    width: 50%;
    padding-top: 14px;
    padding-right: 14px;
  }
`;

export const TableTH = styled.div`
  width: 100%;
  padding: 12px 14px 12px 16px;
  -webkit-box-pack: end;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
`;
