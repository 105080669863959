import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import axios from 'axios';
import * as S from './styles';
import * as ModalActions from '../../store/modules/modal/actions';
import { formatPrice } from '../../util/format';

import api from '../../services/api';

const ModalDeliveryBoy = ({
  data,
  onToggleModalDeliveryBoy,
  statusModalDeliveryBoy,
}) => {
  const [loading, setLoading] = useState(false);
  const [couriers, setCouriers] = useState([]);
  const dateStamp = `${+new Date(data.createdAt)}`.substring(6, 13);

  const modal = useRef();

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`couriers`);
      setCouriers(response.data);
    }

    fetchData();
  }, []);

  // Permite que o modal seja fechado ao clicar fora da tela
  useLayoutEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener('mousedown', handleClickOutside);

      return () => {
        window.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);

  function handleClickOutside(e) {
    if (modal.current && !modal.current.contains(e.target)) {
      onToggleModalDeliveryBoy(!statusModalDeliveryBoy);
    }
  }

  const handleUpdateOrder = async (id, status) => {
    setLoading(true);
    const update = await api.put('order', { id, status: status + 1 });

    if (update.status === 200) {
      setLoading(false);
      onToggleModalDeliveryBoy(!statusModalDeliveryBoy);
      return toast.success(`Pedido em rota de entrega!`);
    }
    return toast.error(`Houve um erro ao atualizar o pedido`);
  };

  const entregadores = [
    {
      image_url:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRMgLoBg8WaB7FtbdjaZYlKzWslS1u3yYoaYJ1vZGiz999RvlUc&usqp=CAU',
      name: 'Roberto Carlos',
      deliver_amount: 5,
      status: 'DISPONIVEL',
    },
    {
      image_url:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRMgLoBg8WaB7FtbdjaZYlKzWslS1u3yYoaYJ1vZGiz999RvlUc&usqp=CAU',
      name: 'Lucas Carlos',
      deliver_amount: 19,
      status: 'OCUPADO',
    },
    {
      image_url:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRMgLoBg8WaB7FtbdjaZYlKzWslS1u3yYoaYJ1vZGiz999RvlUc&usqp=CAU',
      name: 'Marcelo Castro',
      deliver_amount: 0,
      status: 'DISPONIVEL',
    },
  ];

  return (
    <S.ContainerModal>
      <S.ContentModal>
        <S.CardModal ref={modal}>
          <S.CardModalHeader>
            <S.NumberOrder>{dateStamp}</S.NumberOrder>
            <S.DateOrder>
              {format(new Date(data.createdAt), 'dd/MM/yyyy')}
            </S.DateOrder>
            <S.HourOrder>
              {format(new Date(data.createdAt), 'HH:mm')}
            </S.HourOrder>
          </S.CardModalHeader>
          <S.CardModalMain>
            <S.HeaderProducts>
              <span>Entregador</span>
              <span>Entregas hoje</span>
              <span>Status</span>
            </S.HeaderProducts>

            {couriers &&
              couriers.map((entregador, index) => (
                <S.CardModalMainProduct key={index}>
                  <div>
                    <img
                      src={`https://ui-avatars.com/api/?name=${entregador.name}`}
                      alt={`${entregador.name}`}
                    />
                    <span>{entregador.name}</span>
                  </div>

                  <span>{entregador.orders.length}</span>

                  <S.DeliveryMan color={'DISPONIVEL' === 'DISPONIVEL'}>
                    DISPONIVEL
                  </S.DeliveryMan>
                </S.CardModalMainProduct>
              ))}

            <S.CardModalMainFooter>
              <span>
                <b>Cliente:</b> {data.Client.name}
              </span>
              <span>
                <b>Endereço:</b> {data.Client.address}
              </span>
              <span>
                <b>Bairro:</b> {data.Client.district}
              </span>
              <span>
                <b>Complemento:</b> {data.Client.complement}
              </span>
              <S.ButtonNextStatus
                onClick={() => handleUpdateOrder(data.id, data.status)}
              >
                {!loading ? (
                  <span>Avançar</span>
                ) : (
                  <S.Loading>
                    <span />{' '}
                  </S.Loading>
                )}
              </S.ButtonNextStatus>
            </S.CardModalMainFooter>
          </S.CardModalMain>
        </S.CardModal>
      </S.ContentModal>
    </S.ContainerModal>
  );
};

const mapStateToProps = (state) => ({
  statusModalDeliveryBoy: state.modal.statusDeliveryBoy,
});

const mapDispatchToProps = (dispatch) => ({
  onToggleModalDeliveryBoy: (statusModalDeliveryBoy) =>
    dispatch(ModalActions.setModalToggleDeliveryBoy(statusModalDeliveryBoy)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeliveryBoy);
