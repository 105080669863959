import styled from 'styled-components';

export const Container = styled.footer`
  display: block;
  background: #f3f3f6;
  border-radius: 8px;
  box-shadow: 0px 4px 5px 2px #cdcdcd;
  margin-bottom: 30px;

  footer {
    margin-top: 30px;
  }

  div {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    max-width: 1010px;
    line-height: 1;
    font-size: 16px;
    color: rgb(102, 102, 102);
    margin: 0px auto;
    padding: 30px 5px;

    @media (max-width: 1000px) {
      -webkit-box-pack: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }

    strong {
      font-weight: 500;
      @media (max-width: 768px) {
        font-size: 14px;
      }
      b {
        font-weight: bold;
      }
    }
  }

  ul {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    list-style: none;

    @media (max-width: 1000px) {
      flex-wrap: wrap;
      margin-top: 20px;
      font-size: 14px;
    }

    li {
      display: list-item;
      text-align: -webkit-match-parent;

      @media (max-width: 1000px) {
        line-height: 28px;
      }

      :not(:last-child)::after {
        content: '-';
        color: rgb(102, 102, 102);
        margin: 0px 4px;
      }
      a {
        padding: 10px 10px;
        color: rgb(102, 102, 102);
        text-decoration: none;
      }
    }
  }

  /* flex-shrink: 0;
  text-align: center;
  background-color: #f3f3f6;
  color: #191919; */
`;
