import styled from 'styled-components';
import { lighten } from 'polished';
import { ColorText } from '../../styles/variables';

export const ContainerModal = styled.div`
  z-index: 999;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;

  @media (max-width: 768px) {
    transform: initial;
    overflow-y: scroll;
  }
`;

export const ContentModal = styled.div`
  position: absolute;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 0%,
    transparent
  );
`;

export const CardModal = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 700px;
  min-width: 800px;
  max-height: 800px;
  overflow-y: scroll;
  /* padding: 30px 20px; */
  border-radius: 4px;
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.3);
  transform: translateY(5%);
  border: none;

  @media (max-width: 768px) and (min-width: 500px) {
    width: 80%;
    min-width: 80%;
    position: absolute;
    top: 50px;
  }

  @media (max-width: 499px) {
    width: 95%;
    min-width: 95%;
    margin: 0 auto;
    position: absolute;
    top: 5%;
  }
`;

export const CardModalHeader = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;

  @media (max-width: 768px) {
    flex-direction: column;
    span {
      & + span {
        margin-top: 10px;
      }
    }
  }
`;

export const NumberOrder = styled.span`
  background: #ceae8d;
  color: #fff;
  padding: 5px 20px;
  border-radius: 25px;
  font-weight: 700;
  font-size: 16px;
`;

export const DateOrder = styled.span`
  color: ${ColorText};
  font-size: 16px;
  background: ${ColorText};
  color: #fff;
  padding: 5px 20px;
  font-weight: 700;
  border-radius: 25px;
`;

export const HourOrder = styled.span`
  color: ${ColorText};
  font-size: 16px;
  background: ${ColorText};
  color: #fff;
  padding: 5px 20px;
  font-weight: 700;
  border-radius: 25px;
`;

export const CardModalMain = styled.div`
  background: #f9f9f9;
  padding: 20px 0px 0px 0px;
`;

export const HeaderProducts = styled.div`
  display: grid;
  grid-template-columns: 40% 20% 20% 20%;

  span {
    font-size: 16px;
    color: ${ColorText};
    font-weight: 700;
  }

  span {
    text-align: center;
  }
`;

export const CardModalMainProduct = styled.div`
  display: grid;
  grid-template-columns: 40% 20% 20% 20%;
  margin: 20px 0px;
  text-align: center;

  div {
    display: flex;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
      max-width: 60px;
      max-height: 60px;
      object-fit: cover;
      border-radius: 50%;
      margin: 0px 30px 0px 20px;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${ColorText};
    font-size: 14px;
    font-weight: 500;
  }
`;

export const CardModalTotal = styled.span`
  color: ${ColorText};
  font-size: 18px;
  font-weight: 500;
`;

export const CardModalMainFooter = styled.div`
  padding: 20px 40px 20px 30px;
  margin-top: 30px;
  display: flex;
  justify-content:space-between;
  align-items: center;
  background: #fff;
  border-top: 1px solid #ddd;
  /* background: ${ColorText}; */

  @media (max-width: 768px) {
    flex-direction: column;
    span{
      & + span {
        margin-top: 10px;
      }
    }
  }

  span{
    color: ${ColorText};
  }
`;

export const CardModalGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 50px 0px 30px;
  margin: 30px 0px;

  p {
    color: ${ColorText};
    font-size: 18px;
    font-weight: 500;
    padding: 0px 20px;
    word-wrap: break-word;
  }
`;

export const ButtonNextStatus = styled.span`
  padding: 5px 20px;
  background: #28a745;
  border-radius: 4px;
  width: 100px;
  height: 30px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s ease-in-out;
  position: relative;
  :disabled {
    color: #333;
  }
  span {
    color: #fff !important;
  }

  &:hover {
    background: ${lighten(0.06, '#28a745')};
    cursor: pointer;
  }
`;

export const Loading = styled.div`
  width: 18px;
  height: 18px;
  position: absolute;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%);

  span {
    display: block;
    width: 18px;
    height: 18px;
    border: 2px solid transparent;
    border-radius: 50%;
    border-right-color: #fff;
    animation: spinner-animated 0.8s linear infinite;
  }

  @keyframes spinner-animated {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
