export function makeClientRequest(userData) {
  return {
    type: '@client/CREATE_REQUEST',
    userData,
  };
}

export function makeClientSuccess(client) {
  return {
    type: '@client/CREATE_SUCCESS',
    client,
  };
}
