import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import history from '../../../services/history';
import api from '../../../services/api';

import { makeClientSuccess } from './actions';

function* makeClient({ userData }) {
  const { data: userExists } = yield call(
    api.get,
    `client/${userData.phone_number}`
  );

  let clientResponse;

  try {
    if (userExists && !userExists.error) {
      clientResponse = yield call(api.put, `client`, userData);
    } else {
      clientResponse = yield call(api.post, `client`, userData);
    }
    yield put(makeClientSuccess(clientResponse.data));
    toast.success(`${userData.name} seja bem vindo(a) !`);
    history.push('/delivery-choice');
  } catch (e) {
    toast.error('Todo mundo erra, infelizmente desta vez fomos nós :(');
  }
}

export default all([takeLatest('@client/CREATE_REQUEST', makeClient)]);
